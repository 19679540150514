export const roomTypeArray = [
  'RSK',
  'RST',
  'RDK',
  'RDT',
  'RDH',
  'RPK',
  'RPT',
  'RPH',
  'CDK',
  'CDT',
  'CDH',
  'CPK',
  'CPT',
  'CPH',
  'STK',
  'STH',
  'SJK',
  'SJT',
  'SJH',
  'SDK',
  'SDH',
  'SRK',
  'SPK',
  'TDK',
  'TDH',
  'THK',
  'THQ',
  'TSK',
  'TSQ',
  'HJH',
  'HVS',
  'DBD',
  'TWD',
  'QEA',
  'TWA',
  'QEE',
  'TWE',
  'KHC',
  'KHA',
  'QEC',
  'TWC',
  'QEAEF',
  'KGAEF',
  'SKC',
  'SUP',
  'AWM',
  'TD',
  'DLX',
  'SUD',
  'SUH',
  'SUK',
  'SUT',
  'GCUD',
  'GCUK',
  'GCUT',
  'PDEQ',
  'PDED',
  'PDEK',
  'PDET',
  'PDCK',
  'PDCQ',
  'PDCT',
  'PDCD',
  'DTR',
  'DFQ',
  'EFQ',
  'ESK',
  'EST',
];
