import axios from 'axios';
import { AvailablePaymentMethod } from 'types/AvailablePaymentMethod';
import { WebPayment } from 'types/WebPayment';
import {
  MakePaymentByTokenRequestBody,
  MakePaymentRequestBody,
} from 'types/MakePaymentRequestBody';
import { QrPayment } from 'types/QrPayment';
import {
  CheckQrPaymentStatusRequestBody,
  CheckQrPaymentStatusResponse,
} from 'types/CheckQrPaymentStatus';
import { CheckWebPaymentStatusResponse } from 'types/CheckWebPaymentStatus';
import * as qs from 'query-string';
import { HotelLogo } from 'types/HotelLogo';
import { PreArrivalReservation } from 'types/PrearrivalRecord';
import { Theme } from '@material-ui/core';
import { HotelReservation } from 'types/HotelReservation';
const { REACT_APP_API_URL } = process.env;

const qsObject: any = qs.parse(window.location.search);

const apiAxios = axios.create({
  headers: {
    authorization:
      'Bearer ' + (localStorage.getItem('token') ?? qsObject.token),
  },
});

export const getConfirmationBooking = async (id: string): Promise<any> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}confirmations/records/${id}`)
    .then((response) => response.data);
};

export const getPrearrivalBooking = async (
  id: string
): Promise<PreArrivalReservation> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}prearrivals/records/${id}`)
    .then((response) => response.data);
};

export const getAvailablePaymentMethods = async (
  bookingNumber?: string
): Promise<AvailablePaymentMethod[]> => {
  return apiAxios
    .get(
      `${REACT_APP_API_URL}payments/availablemethods` +
        (bookingNumber ? '?bookingNumber=' + bookingNumber : '')
    )
    .then((response) => response.data);
};

export const makeWebPayment = async (
  postBody: MakePaymentRequestBody
): Promise<WebPayment> => {
  return await apiAxios
    .post(`${REACT_APP_API_URL}payments`, postBody)
    .then((response) => response.data);
};

export const makeQrPayment = async (
  postBody: MakePaymentRequestBody
): Promise<QrPayment> => {
  return await apiAxios
    .post(`${REACT_APP_API_URL}payments`, postBody)
    .then((response) => response.data);
};

export const checkQrPaymentStatus = async (
  postBody: CheckQrPaymentStatusRequestBody
): Promise<CheckQrPaymentStatusResponse> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}payments/status?${qs.stringify(postBody as any)}`)
    .then((response) => response.data);
};

export const checkWebPaymentStatus = async (
  postBody: CheckQrPaymentStatusRequestBody
): Promise<CheckWebPaymentStatusResponse> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}payments/status?${qs.stringify(postBody as any)}`)
    .then((response) => response.data);
};

export const payPlanetDepositByToken = async (
  postBody: MakePaymentByTokenRequestBody
) => {
  return apiAxios
    .post(`${REACT_APP_API_URL}payments/planet/withtoken`, postBody)
    .then((response) => response.data);
};

export const getHotelConfigById = async (jwt: string): Promise<any> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}configurations/hotel/prearrivals`)
    .then((response) => response.data);
};

export const getAuthedHotelLogo = async (): Promise<HotelLogo> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}hotels/authed/logo`)
    .then((response) => response.data);
};

export const getHotelTheme = async (): Promise<Theme> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}themes/theme`)
    .then((response) => response.data);
};

export const searchPrearrivalBooking = async (
  bookingNumber: string,
  lastName: string,
  firstName: string
): Promise<any> => {
  const token = localStorage.getItem('token') ?? qsObject.token;
  return axios
    .get(
      `${REACT_APP_API_URL}prearrivals/booking?bookingNumber=${bookingNumber}&lastName=${lastName}&firstName=${firstName}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data);
};

export const regToken = async (hotelId: string): Promise<string> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}remotes/auth?hotelId=${hotelId}`)
    .then((response) => response.data);
};

export const uploadPrearrivalDocument = async (
  bookingNumber: string,
  label: string,
  imageBase64: string,
  preArrivalId: string,
  documentType: string,
  profileId: string
): Promise<any> => {
  try {
    return apiAxios
      .post(`${REACT_APP_API_URL}prearrivals/check-upload-document`, {
        bookingNumber,
        profileId: profileId,
        preArrivalId: preArrivalId,
        label: label,
        image: imageBase64,
        documentType: documentType,
      })
      .then((response) => response);
  } catch (error) {
    console.log(error);
  }
};

export const getPrearrivalConfig = async (): Promise<any> => {
  return apiAxios
    .get(`${REACT_APP_API_URL}configurations/prearrivals-config`)
    .then((response) => response.data);
};

export const addGuestToBooking = async (
  profile: {
    bookingNumber: string;
    firstName: string;
    lastName: string;
  },
  prearrivalRecordId: string
): Promise<any> => {
  return apiAxios
    .post(`${REACT_APP_API_URL}prearrivals/register/${prearrivalRecordId}`, {
      profile,
      isNewGuest: true,
    })
    .then((response) => response);
};

export const updateGuestProfile = async (
  data: any,
  prearrivalRecordId: string
): Promise<any> => {
  return apiAxios
    .post(
      `${REACT_APP_API_URL}prearrivals/register/${prearrivalRecordId}`,
      data
    )
    .then((response) => response);
};
