import React, { useEffect, useState } from 'react';
import { getAuthedHotelLogo } from 'api';

export const Navigation: React.FC = () => {
  const [hotelLogo, setHoteLogo] = useState<string>();

  useEffect(() => {
    if (localStorage.getItem('token') !== null && hotelLogo === undefined) {
      getAuthedHotelLogo().then((response) => {
        setHoteLogo(response.hotelLogo);
        // localStorage.setItem('hotelName', response.hotelName);
        // localStorage.setItem('hotelNameTC', response.hotelNameTC);
        // localStorage.setItem('hotelNameSC', response.hotelNameSC);
      });
    }
  }, [hotelLogo, localStorage.getItem('token')]);
  /*

  const { formatMessage, locale } = useIntl();

  return (
    
      <ul className={css(list.container)}>
      {Object.keys(AppRoute).map(elem => (
        <li key={elem} className={css(list.item)}>
          <NavLink
            exact
            className={css(link.primary)}
            activeClassName={css(link.active)}
            to={localizeRouteKey(AppRoute[elem])}
          >
            {formatMessage({ id: AppRouteTitles.get(AppRoute[elem]) || '' })}
          </NavLink>
        </li>
      ))}
    </ul>
    
        
  );

  function localizeRouteKey(path: string) {
    return `/${CONTEXT_ROOT}/${locale}` + formatMessage({ id: path });
  }
*/
  return (
    <>
      {hotelLogo ? (
        <img
          alt="logo"
          src={hotelLogo}
          style={{ width: 'auto', height: '45px' }}
        />
      ) : (
        <div style={{ width: '45px', height: '45px' }} />
      )}
    </>
  );
};
