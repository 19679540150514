export enum AppRoute {
  AppInit = 'routes.appInit',
  Review = 'routes.review',
  HdfForm = 'routes.hdfForm',
  Registration = 'routes.registration',
  Confirm = 'routes.confirm',
  Error = 'routes.error',
  Checkinpayment = 'routes.checkinpayment',
  Confirmpayment = 'routes.confirmpayment',
  Search = 'routes.search',
  SelectDocumentType = 'routes.selectDocumentType',
  TakeDocumentPhoto = 'routes.takeDocumentPhoto',
  AddGuest = 'routes.addGuest',
}

export const AppRouteTitles = new Map([
  [AppRoute.Review, 'review.menu.name'],
  [AppRoute.HdfForm, 'review.menu.hdfForm'],
  [AppRoute.Registration, 'registration.menu.name'],
  [AppRoute.Confirm, 'confirm.menu.name'],
  [AppRoute.Error, 'error.menu.name'],
  [AppRoute.Checkinpayment, 'error.menu.name'],
  [AppRoute.Confirmpayment, 'error.menu.name'],
  [AppRoute.Search, 'search.menu.name'],
  [AppRoute.SelectDocumentType, 'selectDocumentType.menu.name'],
  [AppRoute.TakeDocumentPhoto, 'takeDocumentPhoto.menu.name'],
  [AppRoute.AddGuest, 'addGuest.menu.name'],
]);
