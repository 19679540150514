import { createSlice } from '@reduxjs/toolkit';

const hotelConfigSlice = createSlice({
  name: 'hotelConfig',
  initialState: {
    data: {
      hotelId: '',
    },
  },
  reducers: {
    setHotelConfig: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setHotelConfig } = hotelConfigSlice.actions;

export const selectHotelConfig = (state: any) => state.hotelConfig.data;

export default hotelConfigSlice.reducer;
