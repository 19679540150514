import { createSlice } from '@reduxjs/toolkit';

const bookingSlice = createSlice({
  name: 'booking',
  initialState: {
    data: {
      referenceNo: '',
      price: '',
      paymentStatus: '',
      id: '',
      status: '',
      email: '',
      reservationId: '',
      profileId: '',
      confirmationNumber: '',
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      country: '',
      zipCode: '',
      telephone: '',
      flightNumber: '',
      flightArrivalTime: '',
      dateOfBirth: '',
      billItems: [],
      deposits: [],
      accompanyGuests: [],
      roomToChargeTypeCode: '',
      roomToChargeTypeDescription: '',
      currentAccompanyGuestId: '',
    },
  },
  reducers: {
    setBookingData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setBookingData } = bookingSlice.actions;

export const selectBookingData = (state: any) => state.booking.data;

export default bookingSlice.reducer;
