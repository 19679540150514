import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import paymentSuccessImg from 'assets/confirmPaymentSuccess.png';
import axios from 'axios';
import { roomTypeArray } from 'const/roomTypes';
import dayjs from 'dayjs';
import * as qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { AvailablePaymentMethod } from 'types/AvailablePaymentMethod';
import { QrPayment } from 'types/QrPayment';
import { WebPayment } from 'types/WebPayment';
import useInterval from 'use-interval';
import {
  checkQrPaymentStatus,
  checkWebPaymentStatus,
  getAvailablePaymentMethods,
  getConfirmationBooking,
  makeQrPayment,
  makeWebPayment,
} from '../api';
import { Primary, Secondary } from '../theme/colors';
import { useDispatch, useSelector } from 'react-redux';
import { selectBookingData } from 'store/slice/bookingSlice';

const { REACT_APP_API_URL } = process.env;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  label: {
    marginRight: theme.spacing(15),
  },
  show: {
    display: 'block',
  },
  hide: {
    display: 'none',
  },
  btnWhite: {
    width: '100%',
    background: Primary,
    color: Secondary,
  },
}));

const BookingSummary = (props: any) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              color: '#808080',
              fontSize: '1.2rem',
              marginTop: 40,
              marginBottom: 40,
            }}
          >
            <FormattedMessage id="review.title" />
          </Grid>
          <Grid item xs={6} style={{ color: '#808080' }}>
            <FormattedMessage id="review.confirm.no" />
          </Grid>
          <Grid item xs={6}>
            {props.data.confirmationNumber}
          </Grid>
          <Grid item xs={6} style={{ color: '#808080' }}>
            <FormattedMessage id="review.guest.name" />
          </Grid>
          <Grid item xs={6}>
            {props.data.lastName} {props.data.firstName}
          </Grid>
          <Grid item xs={6} style={{ color: '#808080' }}>
            <FormattedMessage id="review.checkin.date" />
          </Grid>
          <Grid item xs={6}>
            {props.data.checkInDate && props.data.checkOutDate
              ? (dayjs(props.data.checkInDate, 'YYYYMMDDHHmm').isValid()
                  ? dayjs(props.data.checkInDate, 'YYYYMMDDHHmm').format(
                      'YYYY-MM-DD'
                    )
                  : props.data.checkInDate) +
                ' - ' +
                (dayjs(props.data.checkOutDate, 'YYYYMMDDHHmm').isValid()
                  ? dayjs(props.data.checkOutDate, 'YYYYMMDDHHmm').format(
                      'YYYY-MM-DD'
                    )
                  : props.data.checkOutDate)
              : ''}
          </Grid>
          <Grid item xs={6} style={{ color: '#808080' }}>
            <FormattedMessage id="review.reserved.room.type" />
          </Grid>
          <Grid item xs={6}>
            {roomTypeArray.find(
              (element) => element === props.data.roomToChargeTypeCode
            )
              ? intl.formatMessage({
                  id: 'review.room.type.' + props.data?.roomToChargeTypeCode,
                })
              : props.data.roomToChargeTypeDescription}
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                margin: 0,
                position: 'relative',
                top: '50%',
                left: '20%',
              }}
            >
              <Button
                style={{
                  width: '50%',
                  background: Primary,
                  color: Secondary,
                }}
                variant="contained"
                onClick={() => {
                  props.setStep('displayRoomRate');
                }}
              >
                <FormattedMessage id="btn.confirm" />
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

const RoomRateConfirmation = (props: any) => {
  const { data } = props;
  const { billItems = [] } = data;

  return (
    <Container component="main" maxWidth="sm">
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{
            color: '#808080',
            fontSize: '1.2rem',
            marginTop: 40,
            marginBottom: 40,
          }}
        >
          <Grid item xs={12} style={{ color: '#808080' }}>
            Please make the room payment now
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ color: '#808080' }}>
          <FormattedMessage id="choice.roomrate" />：
        </Grid>
        <Grid item xs={6}>
          <FormattedMessage id="choice.total" />：{billItems?.[0]?.currency}
          {billItems?.reduce(
            (prev: any, current: any) => prev + current.totalAmount,
            0
          )}
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              margin: 0,
              position: 'relative',
              top: '50%',
              left: '20%',
            }}
          >
            <Button
              style={{
                width: '50%',
                background: Primary,
                color: Secondary,
              }}
              variant="contained"
              onClick={() => {
                props.setStep('selectPayment');
              }}
            >
              Yes
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

const PaymentSelect = (props: any) => {
  const [paymentMethods, setPaymentMethods] = useState<
    AvailablePaymentMethod[]
  >([]);
  const getPaymentMethods = async () => {
    const paymentMethods = await getAvailablePaymentMethods();
    setPaymentMethods([
      ...paymentMethods.filter((x) => x.type === 'qrCode' || x.type === 'web'),
    ]);
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const intl = useIntl();
  const { data, payment, setPayment, setSelectedPayment } = props;
  const { billItems = [] } = data;

  const onPaymentMethodClick = async (
    methodCode: string,
    type: string,
    paymentType: 'preCheckInSales' | 'preCheckInDeposit'
  ) => {
    // setIsQrPaymentSuccess(undefined);
    // setIsWebPaymentSuccess(undefined);
    // setPaymentFlow('loadingPayment');
    console.log(`${methodCode} ${type}`);
    const postBody = {
      bookingNumber: props.data.confirmationNumber,
      amount: billItems.reduce(
        (prev: any, current: any) => prev + current.totalAmount || 0,
        0
      ),
      currency: billItems?.[0]?.currency || '',
      paymentType: paymentType,
      paymentMethodCode: methodCode,
      paymentFlowType: type,
    };
    setSelectedPayment({ ...postBody });

    try {
      if (type === 'web') {
        setPayment({ ...payment, status: 'loadingPayment' });
        const webPayment = await makeWebPayment(postBody);
        setPayment({
          ...payment,
          type: 'webPayment',
          status: '',
          data: webPayment,
        });
        props.setStep('paying');
      } else if (type === 'qrCode') {
        setPayment({ ...payment, status: 'loadingPayment' });
        const qrPayment = await makeQrPayment(postBody);
        setPayment({
          ...payment,
          type: 'qrPayment',
          status: '',
          data: qrPayment,
        });
        props.setStep('paying');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {billItems && billItems.length > 0 ? (
        <>
          <Grid
            item
            xs={12}
            style={{
              color: '#808080',
              marginTop: 40,
              marginBottom: 40,
              textAlign: 'center',
            }}
          >
            {intl.formatMessage({ id: 'checkinpayment.title' })}
            <br />
            <br />
            <FormattedMessage id="choice.total" />：{billItems[0].currency}
            {billItems.reduce(
              (prev: any, current: any) => prev + current.totalAmount,
              0
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
            }}
          >
            {intl.formatMessage({
              id: 'checkinpayment.selectpaymentmethod',
            })}
          </Grid>

          <Grid item container spacing={2}>
            {paymentMethods.map((x, i) => (
              <Grid item sm={3} key={i}>
                <Button
                  fullWidth
                  variant="contained"
                  type="button"
                  onClick={() =>
                    onPaymentMethodClick(
                      x.method.code,
                      x.type,
                      'preCheckInSales'
                    )
                  }
                  style={{ background: 'transparent' }}
                >
                  <img
                    width={'100%'}
                    src={`data:image/png;base64,${x.method.logo}`}
                    alt={x.method.textEN}
                  />
                </Button>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>No billItem</>
      )}
    </>
  );
};

const MakePayment = (props: any) => {
  const { payment, setPayment, booking, selectedPayment } = props;
  const { type, status, data } = payment;
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const [webPayment, setWebPayment] = useState<WebPayment>();
  const [qrPayment, setQrPayment] = useState<QrPayment>();
  const [isQrPaymentSuccess, setIsQrPaymentSuccess] = useState<
    boolean | undefined
  >();
  const [isWebPaymentSuccess, setIsWebPaymentSuccess] = useState<
    boolean | undefined
  >();

  const [webPaymentToken, setWebPaymentToken] = useState<string | undefined>();

  useInterval(
    () => {
      if (type === 'qrPayment' && data?.transactionCode !== undefined) {
        checkQrPaymentStatus({
          ...data,
          ...selectedPayment,
          startTime: data.startTime,
          transactionCode: data.transactionCode,
        })
          .then((response) => {
            console.log('PAY OK');
            setIsQrPaymentSuccess(true);
            setPayment({ ...payment, status: 'paymentSuccess' });
            props.setStep('completed');
          })
          .catch((e: any) => {
            if (e.response?.data?.code !== 'P1') {
              setPayment({ ...payment, status: 'paymentError' });
              // setQrPayment(undefined);
            }
          });
      }
    },
    status !== 'paymentSuccess' ? 5000 : null
  );

  useInterval(
    () => {
      if (type === 'webPayment' && data?.merchantRef !== undefined) {
        checkWebPaymentStatus({
          ...data,
          ...selectedPayment,
          transactionCode: data.merchantRef,
        })
          .then((response) => {
            console.log('PAY OK');
            setIsWebPaymentSuccess(true);
            if (data.paymentType === 'preCheckInSales') {
              setPayment({ ...payment, status: 'paymentSuccess' });
            }
            if (response.token) {
              setWebPaymentToken(response.token);
            }
            props.setStep('completed');
          })
          .catch((e: any) => {
            if (e.response?.data?.code !== 'P1') {
              setPayment({ ...payment, status: 'paymentError' });
              setWebPayment(undefined);
            }
          });
      }
    },
    status !== 'paymentSuccess' ? 5000 : null
  );

  useEffect(() => {}, []);

  let lang = localStorage.getItem('lang');

  const onPaymentErrorClick = async () => {
    // if (billItems && deposits) {
    //   if (
    //     billItems.length > 0 &&
    //     billItems.reduce((prev, x) => prev + x.totalAmount, 0) > 0 &&
    //     !props.hotel?.hotelName?.includes('Parklane')
    //   ) {
    //     setPaymentFlow('paymentInit');
    //   } else {
    //     setPaymentFlow('depositInit');
    //   }
    // }
  };

  const onIframeLoad = (e: any) => {
    e.persist();
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Grid container spacing={2} style={{ width: '100%' }}>
        <>
          {status === 'loadingPayment' && (
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Grid>
          )}

          {type === 'webPayment' && (
            <iframe
              src={data.link}
              title="Web payment"
              onLoad={(e) => onIframeLoad(e)}
              style={{
                width: '100vw',
                height: '100vh',
              }}
            />
          )}

          {type === 'qrPayment' && (
            <>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {intl.formatMessage({ id: 'checkinpayment.pleasescanqrcode' })}
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={data.qrcode} alt="payment qr code" />
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              fullWidth
              variant="contained"
              className={classes.btnWhite}
              type="button"
              onClick={() => props.setStep('selectPayment')}
            >
              {intl.formatMessage({ id: 'hdf.btn.back' })}
            </Button>
          </Grid>

          {status === 'paymentError' && (
            <Grid
              container
              spacing={4}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Grid item>
                {intl.formatMessage({ id: 'checkinpayment.error' })}
              </Grid>
              <Button
                fullWidth
                variant="contained"
                className={classes.btnWhite}
                type="button"
                onClick={() => onPaymentErrorClick()}
              >
                {intl.formatMessage({ id: 'checkinpayment.continue' })}
              </Button>
            </Grid>
          )}
        </>
      </Grid>
    </Container>
  );
};

const PaymentComplete = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  return (
    <Grid
      container
      spacing={4}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '1rem',
        marginLeft: '.5rem',
      }}
    >
      <Grid item style={{ textAlign: 'center' }}>
        <div style={{ width: '150px', margin: '1rem auto' }}>
          <img
            style={{ width: '100%', height: '100%' }}
            src={paymentSuccessImg}
          />
        </div>
        <h2 style={{ margin: '1rem 0' }}>THANK YOU!</h2>
        <p style={{ margin: '1rem 0' }}>
          {intl.formatMessage({ id: 'checkinpayment.mini.success' })}
        </p>
        <p style={{ margin: '1rem 0' }}>
          {intl.formatMessage({ id: 'checkinpayment.mini.success2' })}
        </p>
        <h3 style={{ margin: '1rem 0' }}>
          {intl.formatMessage({ id: 'checkinpayment.mini.success3' })}
        </h3>
        <p style={{ marginTop: '1rem' }}>
          <a style={{ color: '#111111' }} href="http://www.minihotel.hk">
            www.minihotel.hk
          </a>
        </p>
      </Grid>
      <Grid item>
        <Button
          fullWidth
          variant="contained"
          className={classes.btnWhite}
          type="button"
          onClick={props.onPaymentContinueClick}
        >
          {/*intl.formatMessage({ id: 'checkinpayment.continue' })*/}
          Close
        </Button>
      </Grid>
    </Grid>
  );
};

/*
 *  This component for Mini's "payment confirmation" flow
 *  1. <BookingSummary />       // confirm details
 *  2. <RoomRateConfirmation /> // confirm total $$
 *  3. <PaymentSelect />        // select payment method
 *  4. <MakePayment />          // iframe for web payment
 *  5. <PaymentComplete />      // finish & send noti
 */
export const ConfirmPayment: FC<{
  errMsgObj: any;
}> = (props) => {
  const history = useHistory();
  const intl = useIntl();
  const data = useSelector(selectBookingData);
  const errMsgObj = props.errMsgObj;
  // const { data, errMsgObj } = props;
  // console.log({ hotel, data })

  const [error, setError] = useState({ code: null, message: null });

  const [payment, setPayment] = useState<any>({
    type: '', // either web | qrcode
    status: '', // loading | success | error
    data: {}, // response from backend, web link for iframe | qrcode image
  });

  /* all steps summary --> displayRoomRate --> selectPayment --> paying --> completed  */
  const [step, setStep] = useState<string>('summary');

  const [booking, setBooking] = useState<Object | null>(null);
  const [selectedPayment, setSelectedPayment] = useState<any>({});

  const qsObject: any = qs.parse(window.location.search);
  const { token, id } = qsObject;
  localStorage.token = token;

  const onPaymentContinueClick = async () => {
    // setPaymentFlow('depositInit');
    window.open('about:blank', '_self');
    window.close();
  };

  const getBookingByBookingNumber = async (bookingNumber: string) => {
    try {
      setError({ code: null, message: null });
      const settings = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          authorization: 'Bearer ' + token,
        },
        timeout: 6000000,
      };

      const { data: bookingResponse }: any = await axios.get(
        `${REACT_APP_API_URL}bookings/checkin?bookingNumber=${bookingNumber}`,
        settings
      );
      // console.log({bookingResponse})

      setBooking({
        ...bookingResponse,
        firstName: bookingResponse?.profile?.firstName,
        lastName: bookingResponse?.profile?.lastName,
      });
    } catch (err: any) {
      console.log('getBookingByBookingNumber err:', err);

      setError({
        code: err?.response?.data?.code,
        message: err?.response?.data?.message,
      });
    }
  };

  useEffect(() => {
    if (id !== '' || id !== null) {
      getConfirmationBooking(id).then((data) => {
        setBooking({
          ...data.booking,
          firstName: data.booking.profile.firstName,
          lastName: data.booking.profile.lastName,
        });
      });
    }
  }, [props]);

  return (
    <>
      <h4 style={{ textAlign: 'center' }}>Payment Confirmation</h4>
      {error?.message ? (
        <h5 style={{ textAlign: 'center' }}>
          Error: {error?.code} - {error?.message}
        </h5>
      ) : (
        <></>
      )}
      {!booking ? (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              color: '#808080',
              fontSize: '1.2rem',
              marginTop: 40,
              marginBottom: 40,
              textAlign: 'center',
            }}
          >
            {props.errMsgObj.errCode === '' ? (
              intl.formatMessage({ id: 'loading.content' })
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      ) : (
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          {payment.status}
          {step !== 'completed' ? (
            <>
              {step === 'summary' && (
                <BookingSummary
                  {...{ setStep, data: { ...data, ...booking }, errMsgObj }}
                />
              )}
              {step === 'displayRoomRate' && (
                <RoomRateConfirmation
                  {...{ setStep, data: { ...data, ...booking } }}
                />
              )}
              {step === 'selectPayment' && (
                <PaymentSelect
                  {...{
                    setStep,
                    data: { ...data, ...booking },
                    payment,
                    setPayment,
                    setSelectedPayment,
                  }}
                />
              )}
              {step === 'paying' && (
                <MakePayment
                  {...{
                    setStep,
                    payment,
                    setPayment,
                    booking: { ...data, ...booking },
                    selectedPayment,
                  }}
                />
              )}
            </>
          ) : (
            <>
              <PaymentComplete {...{ step, setStep, onPaymentContinueClick }} />
            </>
          )}
        </Container>
      )}
    </>
  );
};
