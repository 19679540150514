import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Primary, Secondary } from '../../theme/colors';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { DocumentType } from '../../types/DocumentScanning';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  qrcode: {
    marginTop: theme.spacing(8),
  },
  buttonBox: {
    marginTop: theme.spacing(2),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      width: '80%',
      '& > *': {
        marginInline: theme.spacing(2),
      },
    },
  },
  button1: {
    background: Primary,
    color: Secondary,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
  button2: {
    background: Secondary,
    color: Primary,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
}));

export const SelectDocumentType = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box className={classes.paper}>
        <h4 style={{ color: Primary, marginBottom: '60px' }}>
          <FormattedMessage id="selectDocumentType.title" />
        </h4>

        <Box className={classes.buttonBox}>
          <Button
            className={classes.button1}
            variant="contained"
            type="button"
            onClick={() => {
              history.push('take-document-photo', {
                documentType: DocumentType.SingaporeIdCard,
              });
            }}
          >
            <Typography>
              <FormattedMessage id="btn.identityCard" />
            </Typography>
          </Button>
          <Button
            className={classes.button2}
            variant="contained"
            type="button"
            onClick={() => {
              history.push('take-document-photo', {
                documentType: DocumentType.Passport,
              });
            }}
          >
            <Typography>
              <FormattedMessage id="btn.passport" />
            </Typography>
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
