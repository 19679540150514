import dayjs from 'dayjs';
import axios from 'axios';
import { appStrings, LocalizedRouter, LocalizedSwitch } from 'modules/i18n';
import { AppLayout } from 'modules/layout';
import 'normalize.css';
import * as qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBookingData } from 'store/slice/bookingSlice';
import { setHotelConfig } from 'store/slice/hotelConfigSlice';
import { setTheme } from 'store/slice/themeSlice';
import { useHistory } from 'react-router-dom';
import 'typeface-open-sans';
import {
  getHotelConfigById,
  getPrearrivalBooking,
  getHotelTheme,
  getPrearrivalConfig,
  regToken,
} from '../api';
import { Box, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { SearchPage } from 'views/Search';
import { th } from 'date-fns/locale';
//import redux toolkit Provider

export const AppInit = (props: { qsObject: any }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const [errMsgObj, setErrMsgObj] = useState({ errCode: '', errMsg: '' });

  function parseJwt(jwt: any) {
    if (!jwt) return {};
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  const initializeHotelConfig = async (qsbody: any) => {
    const hotelConfigResult = await getHotelConfigById(qsbody.token);
    const jwtPayload = parseJwt(qsbody.token);
    const { hotelId } = jwtPayload;
    dispatch(
      setHotelConfig({
        ...hotelConfigResult,
        hotelId,
      })
    );
    const hotelPrearrivalConfigResult = await getPrearrivalConfig();
    if (hotelPrearrivalConfigResult) {
      dispatch(
        setHotelConfig({
          ...hotelConfigResult,
          ...hotelPrearrivalConfigResult,
          hotelId,
        })
      );
    }
  };

  async function initializeBooking(qsVar: any) {
    let bookingId = qsVar.id;

    if (!bookingId) {
      const jwtPayload = parseJwt(qsVar.token);
      const { id } = jwtPayload;
      bookingId = id;
    }

    try {
      const data = await getPrearrivalBooking(qsVar.id || bookingId);
      const {
        confirmationNumber,
        profile,
        checkInDate,
        checkOutDate,
        roomToChargeTypeDescription,
        billItems,
        deposits,
        isDepositPaid,
        accompanyGuests,
        roomToChargeTypeCode,
      } = data as any;

      dispatch(
        setBookingData({
          id: bookingId,
          confirmationNumber,
          firstName: profile.firstName,
          lastName: profile.lastName,
          checkInDate: dayjs(checkInDate).format('YYYY-MM-DD'),
          checkOutDate: dayjs(checkOutDate).format('YYYY-MM-DD'),
          roomToChargeTypeCode: roomToChargeTypeCode,
          roomToChargeTypeDescription: roomToChargeTypeDescription,
          profileId: profile.profileId,
          address1: profile.address.address1 ?? '',
          address2: profile.address.address2 ?? '',
          country: profile.address.countryCode,
          zipCode: profile.address.zipCode ?? '',
          email: profile.email.email,
          tel: profile.phoneNumber.number,
          passportNumber: profile.document.documentNumber,
          dateOfBirth: profile.dateOfBirth ?? '',
          billItems: billItems,
          deposits: deposits,
          isDepositPaid,
          accompanyGuests: accompanyGuests.map((guest: any, idx: number) => ({
            id: idx + 1,
            status: 'Pending',
            ...guest,
            address1: guest.address?.address1 ?? '',
            address2: guest.address?.address2 ?? '',
            country: guest.address?.countryCode,
            zipCode: guest.address?.zipCode ?? '',
            email: guest.email?.email ?? '',
            tel: guest.phoneNumber?.number ?? '',
            passportNumber: guest.document?.documentNumber ?? '',
            dateOfBirth: guest.dateOfBirth ?? '',
          })),
          price: qsVar.price,
          skip: qsVar.skip,
          paymentstatus: qsVar.paymentstatus,
          referenceNo: qsVar.refno,
          status: qsVar.status ?? '',
        })
      );
    } catch (err: any) {
      console.log(err);
      if (axios.isAxiosError(err)) {
        console.log(err.message);
        setErrMsgObj({
          errCode: err.code || '',
          errMsg: err.message || '',
        });
      }
    }
  }

  useEffect(() => {
    getHotelTheme().then((response) => {
      dispatch(
        setTheme({
          isInitialized: true,
          theme: response,
        })
      );
    });
  }, [localStorage.getItem('token')]);

  function initializeLocalStorage(qsVar: any) {
    localStorage.clear();
    localStorage.setItem('token', qsVar.token);

    const langPath =
      window.location.pathname
        .split('/')
        .find((item) => ['en', 'hk', 'cn'].includes(item)) || 'en';

    const langQuery = qsVar.lang;
    const lang =
      langQuery !== undefined && ['en', 'hk', 'cn'].includes(langQuery)
        ? langQuery
        : langPath;
    localStorage.setItem('lang', lang);
  }

  const initializeApp = async () => {
    const qsVar: any = props.qsObject;

    if (qsVar.token) {
      await initializeBooking(qsVar);
      initializeLocalStorage(qsVar);
      initializeHotelConfig(qsVar);
    } else {
      console.log('no token');

      setErrMsgObj({
        errCode: '404',
        errMsg: 'Bad Request',
      });
    }

    if (
      window.location.href.startsWith('https://precheckin.royalplaza.com.hk/')
    ) {
      console.log('is royal');
      localStorage.clear();
      const token = await regToken('633e5647d02dd2bf990ed96c');
      localStorage.setItem('token', token);
    }
  };

  useEffect(() => {
    const initializeAppHandler = async () => {
      await initializeApp();
      setIsInitialized(true);
    };

    initializeAppHandler();
  }, []);

  useEffect(() => {
    if (isInitialized) {
      history.push('booking-review', {
        errCode: errMsgObj.errCode,
        errMsg: errMsgObj.errMsg,
      });
    }
  }, [isInitialized]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        style={{
          color: '#808080',
          fontSize: '1.2rem',
          marginTop: 40,
          marginBottom: 40,
          textAlign: 'center',
        }}
      >
        <FormattedMessage id="loading.content" />
      </Grid>
    </Grid>
  );
};
