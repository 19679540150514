import { useLocation } from 'react-router-dom'; // Make sure to install react-router-dom if you haven't
import { selectHotelConfig } from '../store/slice/hotelConfigSlice';
import { useSelector } from 'react-redux';

const useGetSpecialLayoutValue = (key: string) => {
  const location = useLocation(); // Get the current location from React Router
  const hotelConfig = useSelector(selectHotelConfig);
  const pathName = location.pathname
    ?.replace('/en/', '')
    .replace('/hk/', '')
    .replace('/cn/', '');

  // This will compute the special layout value
  const specialLayoutValue =
    hotelConfig?.layout?.[pathName]?.specialLayout?.[key];

  return specialLayoutValue;
};

export default useGetSpecialLayoutValue;
