export enum DocumentScanningStage {
  Stage1 = 'SELECT_IMAGE_TAKING_METHOD',
  Stage2 = 'TAKE_DOCUMENT_PHOTO',
  Stage3 = 'DOCUMENT_PREVIEW',
  Stage4 = 'UPLOADING_DOCUMENT',
  Stage5 = 'SCANNING_RESULT',
}

export enum DocumentTakingMethod {
  Camera = 'CAMERA',
  Upload = 'UPLOAD',
}

export enum DocumentType {
  SingaporeIdCard = 'singaporeIdCard',
  Passport = 'passport',
}
