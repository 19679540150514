import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from '@material-ui/core';
import { Primary, Secondary } from '../theme/colors';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  children: React.ReactNode;
}
const Modal = ({ open, onClose, onConfirm, title, children }: DialogProps) => {
  const useStyles = makeStyles((theme) => ({
    button1: {
      background: Primary,
      color: Secondary,
    },
    button2: {
      background: Secondary,
      color: Primary,
    },
  }));
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box mb={2}>{children}</Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} className={classes.button1}>
          <FormattedMessage id="btn.cancel" />
        </Button>
        <Button onClick={onConfirm} autoFocus className={classes.button2}>
          <FormattedMessage id="btn.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default Modal;
