import { AppLanguage, AppRoute } from 'const';
import {
  Review,
  AppInit,
  Confirm,
  CheckInPayment,
  ConfirmPayment,
  GeneralError,
  HdfLayout,
  Registration,
  SelectDocumentType,
  TakeDocumentPhoto,
  AddGuest,
} from 'views';
import { appStrings, LocalizedRouter, LocalizedSwitch } from 'modules/i18n';
import { AppLayout } from 'modules/layout';
import 'normalize.css';
import * as qs from 'query-string';
import React, { useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import FatalErrorBoundary from './views/ErrorBoundary';
import 'typeface-open-sans';
import { Provider } from 'react-redux';
import store from './store/configureStore';

import { SearchPage } from 'views/Search';

export const App = () => {
  const qsObject = qs.parse(window.location.search);

  return (
    <LocalizedRouter
      RouterComponent={BrowserRouter}
      languages={AppLanguage}
      appStrings={appStrings}
    >
      <Provider store={store}>
        <AppLayout>
          <FatalErrorBoundary>
            <LocalizedSwitch>
              <Route exact path={AppRoute.AppInit}>
                <AppInit qsObject={qsObject} />
              </Route>
              <Route exact path={AppRoute.Review}>
                <Review />
              </Route>
              <Route exact path={AppRoute.Registration}>
                <Registration />
              </Route>
              <Route exact path={AppRoute.HdfForm}>
                <HdfLayout />
              </Route>
              <Route exact path={AppRoute.Search}>
                <SearchPage />
              </Route>
              <Route exact path={AppRoute.Confirm}>
                <Confirm />
              </Route>
              <Route exact path={AppRoute.SelectDocumentType}>
                <SelectDocumentType />
              </Route>
              <Route exact path={AppRoute.TakeDocumentPhoto}>
                <TakeDocumentPhoto />
              </Route>
              <Route exact path={AppRoute.AddGuest}>
                <AddGuest />
              </Route>
              <Route exact path={AppRoute.Checkinpayment}>
                <CheckInPayment />
              </Route>
              <Route exact path={AppRoute.Confirmpayment}>
                <ConfirmPayment errMsgObj={{}} />
              </Route>
              <Route path="*">
                <GeneralError />
              </Route>
            </LocalizedSwitch>
          </FatalErrorBoundary>
        </AppLayout>
      </Provider>
    </LocalizedRouter>
  );
};
