import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import dayjs from 'dayjs';
import { ErrorMessage, Field, Form, Formik, useField } from 'formik';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import {
  DEFAULT_FORM_SELECT_OPTIONS,
  getPhonePlaceholder,
  DEFAULT_FORM_INPUT_CONFIG,
} from '../constants';
import { Primary, Secondary } from '../theme/colors';
import './Registration.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectBookingData, setBookingData } from 'store/slice/bookingSlice';
import { selectHotelConfig } from 'store/slice/hotelConfigSlice';
import useRouteTo from 'hooks/useRouteHandler';
import useGetFormInputConfig from 'hooks/useGetFormInputConfig';
import { updateGuestProfile } from 'api';

const { REACT_APP_API_URL } = process.env;

const MyDatePicker = (props: { name: string }) => {
  const [field, meta, helpers] = useField(props.name);

  const { value } = meta;
  const { setValue } = helpers;

  return (
    <DatePicker
      {...field}
      dateFormat="yyyy-MM-dd"
      selected={value}
      onChange={(date) => setValue(date)}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  show: {
    display: 'block',
  },
  hide: {
    display: 'none',
  },
  btnWhite: {
    width: '50%',
    background: Primary,
    color: Secondary,
  },
  btnGrey: {
    width: '50%',
    backgroundColor: '#000000',
    color: '#000',
  },
  buttonBox: {
    display: 'flex',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      '& > *': {
        marginInline: theme.spacing(2),
      },
    },
  },
  button1: {
    background: Primary,
    color: Secondary,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
  button2: {
    background: Secondary,
    color: Primary,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
}));

export const Registration = () => {
  const history = useHistory();
  const routeToNext = useRouteTo('next');
  const bookingData = useSelector(selectBookingData);
  const intl = useIntl();
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState(getInitialValues());
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();
  const formInputConfig =
    useGetFormInputConfig('registration') ||
    DEFAULT_FORM_INPUT_CONFIG['registration']['fields'];

  const formInputConfigObject = formInputConfig.reduce(
    (acc: any, curr: any) => {
      acc[curr.name] = curr;
      return acc;
    },
    {}
  );

  const selectOptionMap = {
    arrivalHrs: DEFAULT_FORM_SELECT_OPTIONS['registration']['arrivalHrs'],
    country: DEFAULT_FORM_SELECT_OPTIONS['registration']['country'],
    evoucher: DEFAULT_FORM_SELECT_OPTIONS['registration']['evoucher'],
  };

  function getInitialValues() {
    const excludeAutoFillEmailList = [
      'agoda-messaging.com',
      'guest.booking.com',
      'm.expediapartnercentral.com',
      'guest.trip.com',
    ];

    let data = bookingData?.currentAccompanyGuestId
      ? bookingData?.accompanyGuests?.find(
          (guest: any) =>
            guest.profileId === bookingData?.currentAccompanyGuestId
        )
      : bookingData;

    const email = excludeAutoFillEmailList.some((excludeEmail) =>
      data.email?.toLowerCase()?.includes(excludeEmail)
    )
      ? ''
      : data.email;

    return {
      id: bookingData.id,
      //reservationId: reservation_id,
      profileId: data.profileId,
      confirmationNumber: bookingData.confirmationNumber,
      firstName: data.firstName,
      lastName: data.lastName,
      address1: bookingData.address1,
      address2: bookingData.address2,
      country: data.country,
      zipCode: data.zipCode,
      tel: data.telephone,
      flightNumber: data.flightNumber,
      flightArrivalTime: data.flightArrivalTime,
      email: email,
      passportNumber: data.passportNumber,
      dateOfBirth: data.dateOfBirth,
    };
  }

  // props.hotel["id"] = "62c7ffbac0bd1cf8989046c7"
  //localStorage.clear();

  useEffect(() => {
    setInitialValues(getInitialValues());
  }, [bookingData]);

  const url = REACT_APP_API_URL + 'prearrivals/register/' + initialValues.id;

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <span className={classes.show}>
          <h5 style={{ color: '#808080', marginBottom: '60px' }}>
            <FormattedMessage id="reg.reminder" />
          </h5>
        </span>

        <Formik
          initialValues={{
            firstName: initialValues.firstName,
            lastName: initialValues.lastName,
            address1: initialValues.address1,
            address2: initialValues.address2,
            country: initialValues.country,
            zipCode: initialValues.zipCode,
            tel: initialValues.tel,
            flightNumber: initialValues.flightNumber,
            flightArrivalTime: initialValues.flightArrivalTime,
            email: initialValues.email,
            passportNumber: initialValues.passportNumber,
            dateOfBirth:
              initialValues.dateOfBirth !== undefined &&
              initialValues.dateOfBirth !== '' &&
              initialValues.dateOfBirth !== null
                ? new Date(initialValues.dateOfBirth)
                : '',
            methodOfInstMsg: '',
            arrivalHrs: '',
            arrivalMins: '00',
            evoucher: undefined,
          }}
          validationSchema={yup.object().shape({
            //firstName: yup.string().required(intl.formatMessage({ id: 'reg.firstName' })+ " " + intl.formatMessage({ id: 'error.required' })),
            //lastName: yup.string().required(intl.formatMessage({ id: 'reg.lastName' })+ " " + intl.formatMessage({ id: 'error.required' })),
            email: yup
              .string()
              .email()
              .required(
                intl.formatMessage({ id: 'reg.email' }) +
                  ' ' +
                  intl.formatMessage({ id: 'error.required' })
              ),
            address1: formInputConfigObject['address1']?.isRequired
              ? yup
                  .string()
                  .required(
                    intl.formatMessage({ id: 'reg.address1' }) +
                      ' ' +
                      intl.formatMessage({ id: 'error.required' })
                  )
              : yup.string().optional(),
            passportNumber: formInputConfigObject['passportNumber']?.isRequired
              ? yup
                  .string()
                  .required(
                    intl.formatMessage({ id: 'reg.passportNumber' }) +
                      ' ' +
                      intl.formatMessage({ id: 'error.required' })
                  )
              : yup.string().optional(),
            dateOfBirth: formInputConfigObject['dateOfBirth']?.isRequired
              ? yup
                  .string()
                  .required(
                    intl.formatMessage({ id: 'reg.dateOfBirth' }) +
                      ' ' +
                      intl.formatMessage({ id: 'error.required' })
                  )
              : yup.string().optional(),
            country: formInputConfigObject['country']?.isRequired
              ? yup
                  .string()
                  .required(
                    intl.formatMessage({ id: 'reg.country' }) +
                      ' ' +
                      intl.formatMessage({ id: 'error.required' })
                  )
              : yup.string().optional(),
            //zipCode: yup.string().required(intl.formatMessage({ id: 'reg.zip.code' })+ " " + intl.formatMessage({ id: 'error.required' })),
            tel: yup
              .string()
              .required(
                intl.formatMessage({ id: 'reg.tel' }) +
                  ' ' +
                  intl.formatMessage({ id: 'error.required' })
              ),
            arrivalHrs: formInputConfigObject['arrivalHrs']?.isRequired
              ? yup
                  .string()
                  .required(
                    intl.formatMessage({ id: 'reg.arrival.hr' }) +
                      ' ' +
                      intl.formatMessage({ id: 'error.required' })
                  )
              : yup.string().optional(),
            //arrivalMins: yup.string().required(intl.formatMessage({ id: 'reg.arrival.min' })+ " " + intl.formatMessage({ id: 'error.required' })),
            // methodOfInstMsg: yup.string().required(intl.formatMessage({ id: 'reg.method.inst.msg' }) + " " + intl.formatMessage({ id: 'error.required' })),

            /* following 2 fields for DWC */
            //flightNumber: yup
            //  .string()
            //  .matches(
            //    /\b([A-Z]{2}|[A-Z]\d|\d[A-Z])\s?\d{3,4}\b/,
            //    intl.formatMessage({ id: 'error.message.flightNumber' })
            //  )
            //  .optional(),
            flightArrivalTime: formInputConfigObject['flightArrivalTime']
              ?.isRequired
              ? yup
                  .string()
                  .matches(
                    /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
                    intl.formatMessage({
                      id: 'error.message.flightArrivalTime',
                    })
                  )
                  .required(
                    intl.formatMessage({
                      id: 'error.message.flightArrivalTime',
                    })
                  )
              : yup
                  .string()
                  .matches(
                    /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
                    intl.formatMessage({
                      id: 'error.message.flightArrivalTime',
                    })
                  )
                  .optional(),
            evoucher: formInputConfigObject['evoucher']?.isRequired
              ? yup
                  .string()
                  .required(
                    intl.formatMessage({ id: 'error.message.preference' })
                  )
              : yup.string().optional(),
          })}
          onSubmit={async (values: any) => {
            // if (initialValues.confirmationNumber) {
            //   localStorage.setItem(
            //     'confirmationNumber',
            //     initialValues.confirmationNumber
            //   );
            // }
            try {
              let lang = localStorage.getItem('lang');
              if (lang === 'undefined' || lang === '' || lang === null) {
                lang = 'en';
              }

              const data = {
                // "pre-arrival_id": id,
                profile: {
                  profileId:
                    bookingData.currentAccompanyGuestId ||
                    initialValues.profileId,
                  bookingNumber: initialValues.confirmationNumber,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  address: {
                    address1: values.address1,
                    address2: values.address2,
                    countryCode: values.country,
                    zipCode: values.zipCode,
                  },
                  email: values.email,
                  phoneNumber: values.tel || '',
                  document:
                    values.passportNumber !== ''
                      ? {
                          documentNumber: values.passportNumber,
                        }
                      : undefined,
                  dateOfBirth:
                    values.dateOfBirth !== undefined &&
                    values.dateOfBirth !== ''
                      ? dayjs(values.dateOfBirth).format('YYYY-MM-DD')
                      : undefined,
                },
                // "method_of_instant_message": values.methodOfInstMsg,
                flightNumber: values.flightNumber || '',
                // flightArrivalTime: values.flightArrivalTime || '',
                expectedArrivalTime:
                  values.arrivalHrs !== ''
                    ? values.arrivalHrs +
                      ':' +
                      (values.arrivalHrs === '00' ? '01' : values.arrivalMins)
                    : values.flightArrivalTime,
                preferredLanguage: lang,
                evoucher: values.evoucher,
              };

              setDisableBtn(true);

              const updateGuestProfileResult = await updateGuestProfile(
                data,
                initialValues.id
              );

              if (updateGuestProfileResult) {
                setDisableBtn(false);
                routeToNext();
              } else {
                setDisableBtn(false);
                alert(intl.formatMessage({ id: 'error.message2' }));
              }
            } catch (error) {
              console.log('Error Section 1');

              setDisableBtn(false);
              alert(intl.formatMessage({ id: 'error.message2' }));
              return;
            }
          }}
          onReset={(values) => {
            //values.firstName = '';
            //values.lastName = '';
            // values.address1 = '';
            // values.address2 = '';
            // values.country = '';
            // values.zipCode = '';
            // values.tel = '';
            // values.email = '';
            values.methodOfInstMsg = '';
            values.arrivalHrs = '';
            values.arrivalMins = '';
            values.evoucher = undefined;
          }}
        >
          {({ values, errors, handleBlur, handleReset, touched }) => (
            <Form className={classes.form} noValidate onReset={handleReset}>
              <div className={classes.show}>
                <Grid container spacing={2} id="detailForm">
                  {formInputConfig.map((config: any) => (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={2}
                        key={config.name}
                        style={{ color: '#808080' }}
                      >
                        <FormattedMessage
                          id={config.labelId ?? 'reg.' + config.name}
                        />
                        {formInputConfigObject[config.name].isRequired
                          ? '*'
                          : ''}
                      </Grid>
                      {config.type === 'text' ? (
                        <Grid item xs={12} sm={4}>
                          {initialValues[config.name]}
                        </Grid>
                      ) : config.type === 'textarea' ? (
                        <Grid item xs={12} sm={4}>
                          <Field
                            name={config.name}
                            component="textarea"
                            rows={5}
                            maxLength={80}
                            className={
                              'bluepin-input bluepin-placeholder' +
                              (errors[config.name] && touched[config.name]
                                ? ' is-invalid'
                                : '')
                            }
                            placeholder={intl.formatMessage({
                              id: 'reg.' + config.name,
                            })}
                          />
                          <ErrorMessage
                            name={config.name}
                            component="div"
                            className="invalid-feedback"
                          />
                          {config.inputRemarks && (
                            <FormattedMessage id={config.inputRemarks} />
                          )}
                        </Grid>
                      ) : config.type === 'select' ? (
                        <Grid item xs={12} sm={4}>
                          <Field
                            name={config.name}
                            as="select"
                            className={
                              'form-control' +
                              (errors[config.name] && touched[config.name]
                                ? ' is-invalid'
                                : '')
                            }
                          >
                            {selectOptionMap[config.name]?.map(
                              (x: {
                                key: string;
                                value: string;
                                id: string;
                              }) => {
                                return (
                                  <option key={x.key} value={x.value}>
                                    {x.id
                                      ? intl.formatMessage({ id: x.id })
                                      : x.key}
                                  </option>
                                );
                              }
                            )}
                          </Field>
                          <ErrorMessage
                            name={config.name}
                            component="div"
                            className="invalid-feedback"
                          />
                          {config.inputRemarks && (
                            <FormattedMessage id={config.inputRemarks} />
                          )}
                        </Grid>
                      ) : config.type === 'date' ? (
                        <Grid item xs={12} sm={4}>
                          <MyDatePicker name={config.name} />
                          {errors[config.name] && (
                            <div
                              className="invalid-feedback"
                              style={{ display: 'initial' }}
                            >
                              {`${intl.formatMessage({
                                id: 'reg.' + config.name,
                              })} ${intl.formatMessage({
                                id: 'error.required',
                              })}`}
                            </div>
                          )}
                          {config.inputRemarks && (
                            <FormattedMessage id={config.inputRemarks} />
                          )}
                        </Grid>
                      ) : config.type === 'phone' ? (
                        <Grid item xs={12} sm={4}>
                          <PhoneInput
                            name={config.name}
                            international
                            defaultCountry={config.defaultDistrict ?? 'HK'}
                            value={values.tel}
                            placeholder={intl.formatMessage({
                              id: 'reg.' + config.name,
                            })}
                            className={
                              'bluepin-placeholder' +
                              (errors.tel && touched.tel ? ' is-invalid' : '')
                            }
                            onBlur={handleBlur}
                            onChange={(value) => {
                              //console.log(value);
                              values.tel = value;
                            }}
                            labels={getPhonePlaceholder(intl)}
                          />

                          <ErrorMessage
                            name={config.name}
                            component="div"
                            className="invalid-feedback"
                          />
                          {config.inputRemarks && (
                            <FormattedMessage id={config.inputRemarks} />
                          )}
                        </Grid>
                      ) : config.type === 'date' ? (
                        <Grid item xs={12} sm={4}>
                          <MyDatePicker name={config.name} />
                          {errors[config.name] && (
                            <div
                              className="invalid-feedback"
                              style={{ display: 'initial' }}
                            >
                              {`${intl.formatMessage({
                                id: 'reg.' + config.name,
                              })} ${intl.formatMessage({
                                id: 'error.required',
                              })}`}
                            </div>
                          )}
                          {config.inputRemarks && (
                            <FormattedMessage id={config.inputRemarks} />
                          )}
                        </Grid>
                      ) : (
                        //input type text
                        <Grid item xs={12} sm={4}>
                          <Field
                            name={config.name}
                            type="text"
                            className={
                              'bluepin-input bluepin-placeholder' +
                              (errors[config.name] && touched[config.name]
                                ? ' is-invalid'
                                : '')
                            }
                            placeholder={intl.formatMessage({
                              id: config.placeholder ?? 'reg.' + config.name,
                            })}
                          />
                          <ErrorMessage
                            name={config.name}
                            component="div"
                            className="invalid-feedback"
                          />
                          {config.inputRemarks && (
                            <FormattedMessage id={config.inputRemarks} />
                          )}
                        </Grid>
                      )}
                    </>
                  ))}

                  <Grid item xs={12} sm={12}>
                    {/* <div
                      style={{
                        margin: 0,
                        position: 'relative',
                        top: '50%',
                        left: '25%',
                      }}
                    >
                      <Button
                        className={
                          disableBtn ? classes.btnGrey : classes.btnWhite
                        }
                        type="submit"
                        fullWidth
                        variant="contained"
                        //color="primary"
                        disabled={disableBtn}
                      >
                        {disableBtn ? (
                          <CircularProgress size={24} />
                        ) : (
                          <FormattedMessage id="btn.confirm" />
                        )}
                      </Button>
                    </div> */}
                    <Box className={classes.buttonBox}>
                      {bookingData.currentAccompanyGuestId && (
                        <Button
                          className={classes.button2}
                          variant="contained"
                          type="button"
                          onClick={() => {
                            dispatch(
                              setBookingData({
                                ...bookingData,
                                currentAccompanyGuestId: '',
                              })
                            );
                            history.push('add-guest', {
                              isRegisteringAccompany: true,
                            });
                          }}
                        >
                          <Typography>
                            <FormattedMessage id="btn.cancel" />
                          </Typography>
                        </Button>
                      )}

                      <Button
                        className={classes.button1}
                        variant="contained"
                        type="submit"
                        //color="primary"
                        disabled={disableBtn}
                      >
                        <Typography>
                          <FormattedMessage id="btn.confirm" />
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
