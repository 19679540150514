import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { roomTypeArray } from 'const/roomTypes';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Primary, Secondary } from '../theme/colors';
import { useSelector } from 'react-redux';
import { selectBookingData } from 'store/slice/bookingSlice';
import useGetSpecialLayoutValue from 'hooks/useGetLayoutConfig';
import useRouteTo from 'hooks/useRouteHandler';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  label: {
    marginRight: theme.spacing(15),
  },
  show: {
    display: 'block',
  },
  hide: {
    display: 'none',
  },
  buttonBox: {
    display: 'flex',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      '& > *': {
        marginInline: theme.spacing(2),
      },
    },
  },
  button1: {
    background: Primary,
    color: Secondary,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
}));

interface IuseLocation {
  errCode: string;
  errMsg: string;
}

export const Review = () => {
  // localStorage.clear();
  const history = useHistory();
  const location = useLocation<IuseLocation>();
  const { errCode, errMsg } = location.state;
  const routeToNext = useRouteTo('next');
  const showBillItem = useGetSpecialLayoutValue('showBillItems');

  const intl = useIntl();
  const classes = useStyles();

  const [showErr, setShowErr] = useState(false);
  const bookingData = useSelector(selectBookingData);

  useEffect(() => {
    if (
      errCode !== '' &&
      window.location.href.startsWith('https://precheckin.royalplaza.com.hk/')
    ) {
      history.push('search');
    }
    if (!bookingData.confirmationNumber) {
      setShowErr(true);
    } else {
      setShowErr(false);
    }
  }, [bookingData.confirmationNumber, errCode]);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Formik
        initialValues={bookingData}
        onSubmit={() => {
          routeToNext();
        }}
      >
        {() => (
          <Form className={classes.form} noValidate>
            {showErr ? (
              <div>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      color: '#808080',
                      fontSize: '1.2rem',
                      marginTop: 40,
                      marginBottom: 40,
                      textAlign: 'center',
                    }}
                  >
                    {/* <FormattedMessage id="error.message.aleadySubmit" /> */}
                    {errCode
                      ? errCode === '404' || errCode === 'H404'
                        ? intl.formatMessage({ id: 'error.message.notFound' })
                        : errMsg === 'already submitted'
                        ? intl.formatMessage({
                            id: 'error.message.aleadySubmit',
                          })
                        : intl.formatMessage({ id: 'error.message.urlInvalid' })
                      : intl.formatMessage({ id: 'loading.content' })}
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div
                className={showErr ? classes.hide : classes.show}
                style={{ marginLeft: '1rem' }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      color: '#808080',
                      fontSize: '1.1rem',
                      marginTop: 40,
                      marginBottom: 40,
                      textAlign: 'center',
                    }}
                  >
                    <FormattedMessage id="review.title" />
                  </Grid>
                  <Grid item xs={6} style={{ color: '#808080' }}>
                    <FormattedMessage id="review.confirm.no" />
                  </Grid>
                  <Grid item xs={6}>
                    {bookingData.confirmationNumber}
                  </Grid>
                  <Grid item xs={6} style={{ color: '#808080' }}>
                    <FormattedMessage id="review.guest.name" />
                  </Grid>
                  <Grid item xs={6}>
                    {bookingData.lastName} {bookingData.firstName}
                  </Grid>
                  <Grid item xs={6} style={{ color: '#808080' }}>
                    <FormattedMessage id="review.checkin.date" />
                  </Grid>
                  <Grid item xs={6}>
                    {bookingData.checkInDate && bookingData.checkOutDate
                      ? (dayjs(
                          bookingData.checkInDate,
                          'YYYYMMDDHHmm'
                        ).isValid()
                          ? dayjs(
                              bookingData.checkInDate,
                              'YYYYMMDDHHmm'
                            ).format('YYYY MMM DD')
                          : bookingData.checkInDate) +
                        ' - ' +
                        (dayjs(
                          bookingData.checkOutDate,
                          'YYYYMMDDHHmm'
                        ).isValid()
                          ? dayjs(
                              bookingData.checkOutDate,
                              'YYYYMMDDHHmm'
                            ).format('YYYY MMM DD')
                          : bookingData.checkOutDate)
                      : ''}
                  </Grid>
                  <Grid item xs={6} style={{ color: '#808080' }}>
                    <FormattedMessage id="review.reserved.room.type" />
                  </Grid>
                  <Grid item xs={6}>
                    {roomTypeArray.find(
                      (element) => element === bookingData.roomTypeCode
                    )
                      ? intl.formatMessage({
                          id:
                            'review.room.type.' +
                            bookingData?.roomToChargeTypeCode,
                        })
                      : bookingData.roomToChargeTypeDescription}
                  </Grid>
                  {showBillItem === true &&
                    bookingData.billItems.length > 0 && (
                      <>
                        <Grid item xs={6} style={{ color: '#808080' }}>
                          <FormattedMessage id="review.total.room.rate" />
                        </Grid>
                        <Grid item xs={6}>
                          HKD
                          {bookingData.billItems
                            .reduce(
                              (prev: any, current: any) =>
                                prev + current.totalAmount,
                              0
                            )
                            .toFixed(2)}
                        </Grid>
                      </>
                    )}
                  {/* <Grid item xs={6} style={{ color: "#808080" }} >
                  <FormattedMessage id="review.preferences" />
                </Grid>
                <Grid item xs={6} >
                  {data.preferences}
                </Grid> */}

                  {/*
                {!isParkLane ? (
                  <></>
                ) : (
                  <Grid item xs={12}>
                    <div
                      style={{
                        margin: 0,
                        position: 'relative',
                        top: '50%',
                        left: '20%',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={fillHdf}
                            onChange={(e) => {
                              setFillHdf(!fillHdf);
                            }}
                            name="symptom"
                            color="primary"
                          />
                        }
                        label={<FormattedMessage id="hdf.ask" />}
                        labelPlacement="end"
                      />
                    </div>
                  </Grid>
                )}
                */}
                  <Box className={classes.buttonBox}>
                    <Button
                      className={classes.button1}
                      variant="contained"
                      type="submit"
                    >
                      <Typography>
                        <FormattedMessage id="btn.confirm" />
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ color: '#808080' }}>
                  &nbsp;
                </Grid>
                <Grid item xs={12} style={{ color: '#808080' }}>
                  &nbsp;
                </Grid>
                {/* <Grid item xs={12} style={{ color: "#808080" }} >
                <FormattedMessage id="review.note" />
              </Grid> */}
                <Grid item xs={12} style={{ color: '#808080' }}>
                  &nbsp;
                </Grid>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};
