import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

interface LinearProgressBarProps {
  progress: number; // Expecting a percentage value between 0 and 100
}

const LinearProgressBar: React.FC<LinearProgressBarProps> = ({ progress }) => {
  // Ensure the progress is between 0 and 100
  const sanitizedProgress = Math.max(0, Math.min(progress, 100));

  return (
    <Box width="100%" mb={2}>
      <Typography variant="body2" color="textSecondary" align="right">
        {sanitizedProgress}%
      </Typography>
      <LinearProgress variant="determinate" value={sanitizedProgress} />
    </Box>
  );
};

export default LinearProgressBar;
