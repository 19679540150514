import { combineReducers, configureStore } from '@reduxjs/toolkit';

import bookingSlice from './slice/bookingSlice';
import hotelConfigSlice from './slice/hotelConfigSlice';
import themeSlice from './slice/themeSlice';

const reducer = combineReducers({
  booking: bookingSlice,
  hotelConfig: hotelConfigSlice,
  theme: themeSlice,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
