import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Primary, Secondary } from '../../theme/colors';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Divider,
  Typography,
  TextField as MuiTextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectBookingData, setBookingData } from 'store/slice/bookingSlice';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { addGuestToBooking } from 'api';
import Modal from 'components/modal';
import { CircularProgress } from '@material-ui/core';

interface useLocation {
  isRegisteringAccompany: boolean;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  qrcode: {
    marginTop: theme.spacing(8),
  },
  buttonBox: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  button1: {
    background: Primary,
    color: Secondary,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
  button2: {
    background: Secondary,
    color: Primary,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
    marginTop: theme.spacing(2),
  },
  registerButton: {
    background: Primary,
    color: Secondary,
  },
  addDeleteButton: {
    background: Primary,
    color: Secondary,
    alignSelf: 'end',
  },
  deleteButtonBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  statusBox: {
    width: '40%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  guestListBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  guestListItem: {
    height: '50px',
  },
  guestStatusBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  inputGroup: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      width: '100%',
      '& > *': {
        marginInline: theme.spacing(2),
        alignSelf: 'center',
      },
    },
  },
  inputMessageBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      width: '50%',
      '& > *': {
        alignSelf: 'center',
      },
    },
  },
  addGuestInputBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  errorMessageBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));

export const AddGuest = () => {
  const location = useLocation<useLocation>();
  const { isRegisteringAccompany } = location.state;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const bookingData = useSelector(selectBookingData);
  const accompanyGuests = bookingData.accompanyGuests;
  const [addingList, setAddingList] = React.useState<any>([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [removingGuestId, setRemovingGuestId] = React.useState(0);
  const [pageStatus, setPageStatus] = React.useState(
    isRegisteringAccompany ? 'registerGuest' : 'addGuest'
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const intl = useIntl();

  const validationSchema = yup.object().shape({
    guests: yup.array().of(
      yup.object().shape({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Family Name is required'),
      })
    ),
  });

  // const onRemoveExistingGuestClick = (id: number) => {
  //   setRemovingGuestId(id);
  //   setIsModalOpen(true);
  // };

  const onRegisterGuestClick = (id: string) => {
    dispatch(
      setBookingData({
        ...bookingData,
        currentAccompanyGuestId: id,
      })
    );
    history.push('registration');
  };

  const onConfirmRemoveGuest = () => {
    const newAccompanyGuests = accompanyGuests.filter(
      (guest: any) => guest.id !== removingGuestId
    );
    dispatch(
      setBookingData({
        ...bookingData,
        accompanyGuests: newAccompanyGuests,
      })
    );
    setIsModalOpen(false);
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Modal
        open={isModalOpen}
        title={intl.formatMessage({ id: 'modal.title.removeGuest' })}
        onClose={() => setIsModalOpen(false)}
        onConfirm={onConfirmRemoveGuest}
      >
        <Typography>
          <FormattedMessage id="accompanyGuest.removeGuest.confirmation" />
        </Typography>
      </Modal>
      <Box className={classes.paper}>
        <h4 style={{ color: Primary, marginBottom: '60px' }}>
          <FormattedMessage id="accompanyGuest.title" />
        </h4>
        <Box className={classes.guestListBox}>
          {[
            {
              firstName: bookingData.firstName,
              lastName: bookingData.lastName,
              status: 'Completed',
            },
            ...accompanyGuests,
          ].map((guest: any, index: number) => (
            <Grid
              container
              key={'exist' + index}
              className={classes.guestListItem}
            >
              <Grid item md={4} xs={6}>
                <Typography>
                  <FormattedMessage id="accompanyGuest.label.guestName" />
                </Typography>
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography>
                  {guest.firstName} {guest.lastName}
                </Typography>
              </Grid>
              <Grid className={classes.guestStatusBox} item md={5} xs={12}>
                {guest.status === 'Completed' ? (
                  <Box className={classes.statusBox}>
                    <Typography>
                      <FormattedMessage id="accompanyGuest.guestStatus.completed" />
                    </Typography>
                  </Box>
                ) : pageStatus === 'registerGuest' ? (
                  <Box className={classes.statusBox}>
                    <Button
                      className={classes.registerButton}
                      variant="contained"
                      type="button"
                      onClick={() => {
                        onRegisterGuestClick(guest.profileId);
                      }}
                    >
                      <Typography>
                        <FormattedMessage id="btn.register" />
                      </Typography>
                    </Button>
                  </Box>
                ) : (
                  <Box className={classes.statusBox}>
                    <Typography>
                      <FormattedMessage id="accompanyGuest.guestStatus.inProgress" />
                    </Typography>
                  </Box>
                )}
              </Grid>
              {/* <Box className={classes.statusBox}>
                     <Button
                    className={classes.registerButton}
                    variant="contained"
                     type="button"
                      onClick={() => {
                       onRemoveExistingGuestClick(guest.id);
                    }}
                   >
                    <Typography>
                       <FormattedMessage id="btn.remove" />
                      </Typography>
                   </Button>
                   </Box> */}
            </Grid>
          ))}
        </Box>

        {pageStatus === 'addGuest' && (
          <Box className={classes.buttonBox}>
            <Button
              className={classes.button2}
              variant="contained"
              type="button"
              onClick={() => {
                const lastId =
                  addingList.length > 0
                    ? addingList[addingList.length - 1].id
                    : 0;
                setAddingList([
                  ...addingList,
                  {
                    id: lastId ? lastId + 1 : 2,
                    firstName: '',
                    lastName: '',
                  },
                ]);
              }}
            >
              <Typography>
                <FormattedMessage id="btn.addGuest" />
              </Typography>
            </Button>
          </Box>
        )}
        {addingList.length > 0 && (
          <Box sx={{ width: '100%', borderBottom: 1, marginY: 2 }}>
            <Divider />
          </Box>
        )}

        <Formik
          initialValues={{ guests: addingList }}
          validationSchema={validationSchema}
          enableReinitialize // Add this line to enable reinitialization
          onSubmit={async (values) => {
            if (pageStatus === 'addGuest') {
              setIsSubmitting(true);
              if (addingList.length === 0) {
                setAddingList([]);
                setPageStatus('registerGuest');
                setIsSubmitting(false);
                return;
              }
              try {
                const addGuestResponse = await Promise.all(
                  addingList.map((guest: any) =>
                    addGuestToBooking(
                      {
                        bookingNumber: bookingData.confirmationNumber,
                        firstName: guest.firstName,
                        lastName: guest.lastName,
                      },
                      bookingData.id
                    )
                  )
                );

                console.log('addGuestResponse', addGuestResponse);

                if (addGuestResponse.every((res: any) => res.status === 200)) {
                  const addingListWithProfileId = [...addingList].map(
                    (guest: any, idx: number) => ({
                      ...guest,
                      profileId: addGuestResponse[idx].data.profileId,
                      status: 'Pending',
                    })
                  );

                  dispatch(
                    setBookingData({
                      ...bookingData,
                      accompanyGuests: [
                        ...accompanyGuests,
                        ...addingListWithProfileId,
                      ],
                    })
                  );
                  setAddingList([]);
                  setPageStatus('registerGuest');
                }
              } catch (error) {
                console.log(error);
              }
              setIsSubmitting(false);
            } else {
              history.push('confirm');
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Form className={classes.form} noValidate>
              {values.guests.map((guest: any, index: number) => (
                <Grid
                  key={'new' + guest.id}
                  container
                  className={classes.addGuestInputBox}
                >
                  <Grid item md={5} xs={12}>
                    <Box className={classes.inputGroup}>
                      <Typography>
                        <FormattedMessage id="accompanyGuest.label.lastName" />
                      </Typography>
                      <Box className={classes.inputMessageBox}>
                        <Field
                          name={`guests[${index}].lastName`}
                          as={MuiTextField}
                          variant="filled"
                          size="small"
                          hiddenLabel
                          onChange={(e: any) => {
                            setAddingList(
                              values.guests.map((g: any, i: number) =>
                                i === index
                                  ? { ...g, lastName: e.target.value }
                                  : g
                              )
                            );
                          }}
                        />
                        <Box className={classes.errorMessageBox}>
                          <ErrorMessage name={`guests[${index}].lastName`}>
                            {(msg) => (
                              <Box className={classes.errorMessage}>{msg}</Box>
                            )}
                          </ErrorMessage>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    <Box className={classes.inputGroup}>
                      <Typography>
                        <FormattedMessage id="accompanyGuest.label.firstName" />
                      </Typography>
                      <Box className={classes.inputMessageBox}>
                        <Field
                          name={`guests[${index}].firstName`}
                          as={MuiTextField}
                          variant="filled"
                          size="small"
                          hiddenLabel
                          onChange={(e: any) => {
                            setAddingList(
                              values.guests.map((g: any, i: number) =>
                                i === index
                                  ? { ...g, firstName: e.target.value }
                                  : g
                              )
                            );
                          }}
                        />
                        <Box className={classes.errorMessageBox}>
                          <ErrorMessage name={`guests[${index}].firstName`}>
                            {(msg) => (
                              <Box className={classes.errorMessage}>{msg}</Box>
                            )}
                          </ErrorMessage>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={2} xs={12} className={classes.deleteButtonBox}>
                    <Button
                      className={classes.addDeleteButton}
                      variant="contained"
                      type="button"
                      onClick={() => {
                        const newGuests = addingList.filter(
                          (_: any, i: number) => i !== index
                        );
                        setAddingList(newGuests);
                      }}
                    >
                      <Typography>
                        <FormattedMessage id="btn.remove" />
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              ))}

              <Box className={classes.buttonBox} sx={{ marginTop: '20px' }}>
                <Button
                  className={classes.button1}
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Typography>
                      <FormattedMessage id="btn.confirm" />
                    </Typography>
                  )}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};
