import React, { useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { wrapper } from 'theme';
import { Navigation } from 'modules/navigation';
//import { LanguageSwitcher } from 'modules/i18n';
import { padding } from 'theme/padding';
import { Primary } from '../../../theme/colors';

import Box from '@material-ui/core/Box';
import { useSelector, useDispatch } from 'react-redux';
import { selectTheme } from 'store/slice/themeSlice';

export const AppLayout: React.FC = ({ children }) => {
  const theme = useSelector(selectTheme) || {};
  const [navColor, setNavColor] = useState(
    theme?.theme?.palette?.primary?.main
  );

  const styles = StyleSheet.create({
    container: {
      flex: '1 1 auto',
      flexDirection: 'column',
      fontFamily: "'Open Sans', sans-serif",
    },
    header: {
      display: 'flex',
      justifyContent: 'space-around',
      backgroundColor: navColor,
      borderBottom: `1px solid ${
        theme?.theme?.palette?.secondary?.main || '#f6f6f6'
      }`,
      padding: '1em 0',
    },
    nav: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
    },
    main: {
      margin: '0 auto',
    },
  });

  return (
    <div className={css(styles.container)}>
      {theme.isInitialized && (
        <header className={css(styles.header)}>
          <nav className={css(styles.nav, wrapper[95])}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent:
                  theme?.theme?.specialConfig?.logoPosition || 'flex-start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Navigation />
            </Box>

            {/* <LanguageSwitcher /> */}
          </nav>

          <link
            href="//netdna.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css"
            rel="stylesheet"
          />
        </header>
      )}
      <main className={css(styles.main, wrapper[90], padding.medium)}>
        {children}
      </main>
    </div>
  );
};
