import { Input } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import React, { createRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Primary, Secondary } from '../theme/colors';
import { useDispatch, useSelector } from 'react-redux';
import { selectBookingData } from 'store/slice/bookingSlice';

import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
// import SignaturePad from 'react-signature-pad-wrapper';
import SignatureCanvas from 'react-signature-canvas';
import * as localizations from '../modules/i18n/localizations';
const { REACT_APP_API_URL } = process.env;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  qrcode: {
    marginTop: theme.spacing(8),
  },
}));

const GuestList = (props: {
  data: any;
  setIsFilling: Function;
  setSelectedGuest: Function;
  hdfData: any;
  setHdfData: Function;
}) => {
  const history = useHistory();
  const { data, setIsFilling, setSelectedGuest, hdfData, setHdfData } = props;
  const { accompanyGuests = [], profile = {} } = data || {};

  const classes = useStyles();
  return (
    <div className={classes.form}>
      <Grid container spacing={2} id="detailForm">
        <Grid
          item
          xs={12}
          sm={4}
          style={{ color: '#808080', margin: 'auto 0' }}
        >
          <FormattedMessage id="hdf.guestName" />:
        </Grid>
        <Grid item xs={12} sm={4}>
          {`${data.lastName} ${data.firstName}`}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            style={{
              width: '30%',
              background: Primary,
              color: Secondary,
            }}
            variant="contained"
            onClick={() => {
              setHdfData({ ...hdfData, ...data });
              setIsFilling(true);
            }}
          >
            <FormattedMessage id="hdf.btn.fill" />
          </Button>
          <sup style={{ margin: '.5rem' }}>
            {!profile?.filledHDF
              ? ''
              : `filled on ${profile?.filledHdfComment?.createdAt}`}
          </sup>
        </Grid>

        {data?.accompanyGuests?.map((accompany: any, i: number) => {
          const { filledHDF = false, filledHdfComment = {} } =
            accompanyGuests.find(({ profileId }: any) => accompany.profileId) ||
            {};

          return (
            <>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ color: '#808080', margin: 'auto 0' }}
              >
                <FormattedMessage id="hdf.accompanyName" /> #{i + 1}:
              </Grid>
              <Grid item xs={12} sm={4}>
                {`${accompany.lastName} ${accompany.firstName}`}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  style={{
                    width: '30%',
                    background: Primary,
                    color: Secondary,
                  }}
                  variant="contained"
                  onClick={() => {
                    // console.log({accompany})
                    const { address, email, phoneNumber } = accompany;
                    setHdfData({
                      ...hdfData,
                      ...accompany,
                      email: email?.email || '',
                      address: `${address?.address1 || ''} ${
                        address?.address2 || ''
                      }`.trim(),
                      contactAddress: `${address?.address1 || ''} ${
                        address?.address2 || ''
                      }`.trim(),
                      contactEmail: email?.email || '',
                      contactPhone: phoneNumber?.number || '',
                    });
                    setIsFilling(true);
                  }}
                >
                  <FormattedMessage id="hdf.btn.fill" />
                </Button>
                <sup style={{ margin: '.5rem' }}>
                  {!filledHDF ? '' : `filled on ${filledHdfComment?.createdAt}`}
                </sup>
              </Grid>
            </>
          );
        })}

        <Grid item xs={12} sm={3}>
          {' '}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            style={{
              width: '50%',
              background: Primary,
              color: Secondary,
            }}
            variant="contained"
            onClick={() => {
              history.push('registration');
              // setIsFilling(true)
            }}
          >
            <FormattedMessage id="hdf.btn.backToReview" />
          </Button>
        </Grid>
        <Grid item xs={12} sm={3}>
          {' '}
        </Grid>
      </Grid>
    </div>
  );
};

const HdfForm = (props: {
  data: any;
  hdfData: any;
  setHdfData: Function;
  setOpenSignature: Function;
  selectedGuest: any;
  errors: any;
  checkRequiedFields: Function;
  isHighRisk: Function;
}) => {
  const [highRiskError, setHighRiskError] = useState(false);
  const dispatch = useDispatch();
  const {
    data,
    hdfData,
    setHdfData,
    selectedGuest,
    setOpenSignature,
    errors,
    checkRequiedFields,
    isHighRisk,
  } = props;
  // console.log({ hdfData })

  const langs = ['en', 'hk', 'cn'];
  // console.log({ localStorage })
  const lang = langs.includes(localStorage.lang) ? localStorage.lang : 'en';
  const locale = localizations[lang];
  // console.log("locale",locale)
  const frequenceUsedCountries = [
    'country.CN',
    'country.HK',
    'country.MO',
    'country.TW',
  ];
  const countries = [
    ...frequenceUsedCountries,
    ...Object.keys(locale).filter((key: string) => {
      return key.includes('country.') && !frequenceUsedCountries.includes(key);
    }),
  ];
  // console.log({ countries })

  const classes = useStyles();
  return (
    <div className={classes.form}>
      <HighRiskPopup {...{ highRiskError, setHighRiskError }} />
      <Grid container spacing={2} id="detailForm">
        <Grid
          item
          xs={12}
          sm={2}
          style={{ color: '#808080', margin: 'auto 0' }}
        >
          <FormattedMessage id="hdf.familyName" />
          <sup style={{ color: 'red' }}>*</sup>:
        </Grid>
        <Grid item xs={12} sm={4} style={{ marginTop: 'auto' }}>
          <TextField
            error={!!errors['lastName']}
            label={errors['lastName']}
            name="lastName"
            value={hdfData.lastName}
            onChange={(e) => {
              const { name, value } = e.target;
              setHdfData({ ...hdfData, [name]: value });
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          style={{ color: '#808080', margin: 'auto 0' }}
        >
          <FormattedMessage id="hdf.firstName" />
          <sup style={{ color: 'red' }}>*</sup>:
        </Grid>
        <Grid item xs={12} sm={4} style={{ marginTop: 'auto' }}>
          <TextField
            error={!!errors['firstName']}
            label={errors['firstName']}
            name="firstName"
            value={hdfData.firstName}
            onChange={(e) => {
              const { name, value } = e.target;
              setHdfData({ ...hdfData, [name]: value });
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={2}
          style={{ color: '#808080', margin: 'auto 0' }}
        >
          <FormattedMessage id="hdf.dateOfBirth" />
          <sup style={{ color: 'red' }}>*</sup>:
        </Grid>
        <Grid item xs={12} sm={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              error={!!errors['dateOfBirth']}
              label={errors['dateOfBirth']}
              disableToolbar
              variant="inline"
              placeholder="YYYY-MM-DD"
              format="yyyy-MM-dd"
              margin="normal"
              autoOk={true}
              name="dateOfBirth"
              value={hdfData.dateOfBirth || null}
              onChange={(date) => {
                dayjs(date).format('YYYY-MM-dd');
                // console.log({name:"dateOfBirth", value:dayjs(date).format("YYYY-MM-DD")})
                setHdfData({
                  ...hdfData,
                  dateOfBirth: dayjs(date).format('YYYY-MM-DD'),
                });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          style={{ color: '#808080', margin: 'auto 0' }}
        >
          <FormattedMessage id="hdf.sex" />
          <sup style={{ color: 'red' }}>*</sup>:
          {errors['sex'] ? (
            <sup style={{ color: 'red' }}>{errors['sex']}</sup>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12} sm={4} style={{ marginTop: 'auto' }}>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="sex" name="sex">
              <FormControlLabel
                value="m"
                onChange={(e) => {
                  setHdfData({ ...hdfData, sex: 'M' });
                }}
                control={<Radio color="primary" />}
                label={locale['hdf.sex.m']}
              />
              <FormControlLabel
                value="f"
                onChange={(e) => {
                  setHdfData({ ...hdfData, sex: 'F' });
                }}
                control={<Radio color="primary" />}
                label={locale['hdf.sex.f']}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={2}
          style={{ color: '#808080', margin: 'auto 0' }}
        >
          <FormattedMessage id="hdf.passport" />
          <sup style={{ color: 'red' }}>*</sup>:
        </Grid>
        <Grid item xs={12} sm={4} style={{ marginTop: 'auto' }}>
          <TextField
            error={!!errors['passport']}
            label={errors['passport']}
            name="passport"
            value={hdfData.passport}
            onChange={(e) => {
              const { name, value } = e.target;
              setHdfData({ ...hdfData, [name]: value });
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          style={{ color: '#808080', margin: 'auto 0' }}
        >
          <FormattedMessage id="hdf.nationality" />
          <sup style={{ color: 'red' }}>*</sup>:
          {errors['nationality'] ? (
            <sup style={{ color: 'red' }}>{errors['nationality']}</sup>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            id="nationality-dropdown"
            options={countries.map((countryKey) => {
              return {
                key: countryKey,
                name: locale[countryKey],
              };
            })}
            getOptionLabel={(option: any) => option.name}
            style={{ width: 300 }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="select country"
                variant="outlined"
              />
            )}
            onChange={(_, newValue: any) => {
              setHdfData({ ...hdfData, nationality: newValue?.name || '' });
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={2}
          style={{ color: '#808080', margin: 'auto 0' }}
        >
          <FormattedMessage id="hdf.comeFrom" />:
        </Grid>
        <Grid item xs={12} sm={4} style={{ margin: 'auto' }}>
          <TextField
            name="comeFrom"
            value={hdfData.comeFrom}
            onChange={(e) => {
              const { name, value } = e.target;
              setHdfData({ ...hdfData, [name]: value });
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          style={{ color: '#808080', margin: 'auto 0' }}
        >
          <FormattedMessage id="hdf.arrivalDate" />:
        </Grid>
        <Grid item xs={12} sm={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              placeholder="YYYY-MM-DD"
              format="yyyy-MM-dd"
              margin="normal"
              autoOk={true}
              name="arrivalDate"
              value={hdfData.arrivalDate || null}
              onChange={(date) => {
                dayjs(date).format('YYYY-MM-dd');
                // console.log({name:"arrivalDate", value:dayjs(date).format("YYYY-MM-DD")})
                setHdfData({
                  ...hdfData,
                  arrivalDate: dayjs(date).format('YYYY-MM-DD'),
                });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={6} sm={6} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormattedMessage id="hdf.placesVisitedInSevenDays" />:
        </Grid>
        <Grid item xs={12} sm={4} style={{ marginTop: 'auto' }}>
          <TextField
            name="placesVisitedInSevenDays"
            value={hdfData.placesVisitedInSevenDays}
            onChange={(e) => {
              const { name, value } = e.target;
              setHdfData({ ...hdfData, [name]: value });
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          style={{ color: '#808080', margin: 'auto 0' }}
        >
          <span
            style={{ color: hdfData.historyOfContact === 'yes' ? 'red' : '' }}
          >
            <FormattedMessage id="hdf.contactWithCovid" />
          </span>
          <sup style={{ color: 'red' }}>*</sup>:
          {errors['historyOfContact'] ? (
            <sup style={{ color: 'red' }}>{errors['historyOfContact']}</sup>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={4} sm={4} style={{ color: '#808080', margin: 'auto 0' }}>
          {' '}
        </Grid>
        <Grid item xs={6} sm={6}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="historyOfContact"
              name="historyOfContact"
            >
              <FormControlLabel
                value="yes"
                onChange={(e) => {
                  setHdfData({ ...hdfData, historyOfContact: 'yes' });
                }}
                control={<Radio color="primary" />}
                label={locale['hdf.contactWithCovid.yes']}
              />
              <FormControlLabel
                value="no"
                onChange={(e) => {
                  setHdfData({ ...hdfData, historyOfContact: 'no' });
                }}
                control={<Radio color="primary" />}
                label={locale['hdf.contactWithCovid.no']}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={2} sm={2} style={{ color: '#808080', margin: 'auto 0' }}>
          {' '}
        </Grid>

        {/* 2nd page of andriod ver. I put in one page sin */}
        <Grid
          item
          xs={12}
          sm={12}
          style={{ color: '#808080', margin: 'auto 0' }}
        >
          <span
            style={{
              color:
                hdfData.symptom.filter((sym: string) => sym !== 'no').length > 0
                  ? 'red'
                  : '',
            }}
          >
            <FormattedMessage id="hdf.symptom" />
          </span>
          <sup style={{ color: 'red' }}>*</sup>:
          {errors['symptom'] ? (
            <sup style={{ color: 'red' }}>{errors['symptom']}</sup>
          ) : (
            ''
          )}
        </Grid>

        <Grid item xs={6} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!hdfData.symptom.find((sym: string) => sym === 'no')}
                onChange={(e) => {
                  const checked = !!hdfData.symptom.find(
                    (sym: string) => sym === 'no'
                  );
                  if (checked) {
                    setHdfData({
                      ...hdfData,
                      symptom: hdfData.symptom.filter(
                        (sym: string) => sym !== 'no'
                      ),
                    });
                    return;
                  }
                  setHdfData({ ...hdfData, symptom: ['no'] });
                }}
                name="symptom"
                color="primary"
              />
            }
            label={<FormattedMessage id="hdf.symptom.no" />}
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  !!hdfData.symptom.find((sym: string) => sym === 'fever')
                }
                onChange={(e) => {
                  const checked = !!hdfData.symptom.find(
                    (sym: string) => sym === 'fever'
                  );
                  if (checked) {
                    setHdfData({
                      ...hdfData,
                      symptom: hdfData.symptom.filter(
                        (sym: string) => sym !== 'fever'
                      ),
                    });
                    return;
                  }
                  setHdfData({
                    ...hdfData,
                    symptom: [
                      ...hdfData.symptom.filter((sym: any) => sym !== 'no'),
                      'fever',
                    ],
                  });
                }}
                name="symptom"
                color="primary"
              />
            }
            label={<FormattedMessage id="hdf.symptom.fever" />}
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  !!hdfData.symptom.find(
                    (sym: string) => sym === 'breathingDifficulty'
                  )
                }
                onChange={(e) => {
                  const checked = !!hdfData.symptom.find(
                    (sym: string) => sym === 'breathingDifficulty'
                  );
                  if (checked) {
                    setHdfData({
                      ...hdfData,
                      symptom: hdfData.symptom.filter(
                        (sym: string) => sym !== 'breathingDifficulty'
                      ),
                    });
                    return;
                  }
                  setHdfData({
                    ...hdfData,
                    symptom: [
                      ...hdfData.symptom.filter((sym: any) => sym !== 'no'),
                      'breathingDifficulty',
                    ],
                  });
                }}
                name="symptom"
                color="primary"
              />
            }
            label={<FormattedMessage id="hdf.symptom.breathingDifficulty" />}
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  !!hdfData.symptom.find((sym: string) => sym === 'cough')
                }
                onChange={(e) => {
                  const checked = !!hdfData.symptom.find(
                    (sym: string) => sym === 'cough'
                  );
                  if (checked) {
                    setHdfData({
                      ...hdfData,
                      symptom: hdfData.symptom.filter(
                        (sym: string) => sym !== 'cough'
                      ),
                    });
                    return;
                  }
                  setHdfData({
                    ...hdfData,
                    symptom: [
                      ...hdfData.symptom.filter((sym: any) => sym !== 'no'),
                      'cough',
                    ],
                  });
                }}
                name="symptom"
                color="primary"
              />
            }
            label={<FormattedMessage id="hdf.symptom.cough" />}
            labelPlacement="end"
          />
        </Grid>

        <Grid item xs={6} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  !!hdfData.symptom.find((sym: string) => sym === 'hiarrhoea')
                }
                onChange={(e) => {
                  const checked = !!hdfData.symptom.find(
                    (sym: string) => sym === 'hiarrhoea'
                  );
                  if (checked) {
                    setHdfData({
                      ...hdfData,
                      symptom: hdfData.symptom.filter(
                        (sym: string) => sym !== 'hiarrhoea'
                      ),
                    });
                    return;
                  }
                  setHdfData({
                    ...hdfData,
                    symptom: [
                      ...hdfData.symptom.filter((sym: any) => sym !== 'no'),
                      'hiarrhoea',
                    ],
                  });
                }}
                name="symptom"
                color="primary"
              />
            }
            label={<FormattedMessage id="hdf.symptom.diarrhoea" />}
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  !!hdfData.symptom.find((sym: string) => sym === 'headache')
                }
                onChange={(e) => {
                  const checked = !!hdfData.symptom.find(
                    (sym: string) => sym === 'headache'
                  );
                  if (checked) {
                    setHdfData({
                      ...hdfData,
                      symptom: hdfData.symptom.filter(
                        (sym: string) => sym !== 'headache'
                      ),
                    });
                    return;
                  }
                  setHdfData({
                    ...hdfData,
                    symptom: [
                      ...hdfData.symptom.filter((sym: any) => sym !== 'no'),
                      'headache',
                    ],
                  });
                }}
                name="symptom"
                color="primary"
              />
            }
            label={<FormattedMessage id="hdf.symptom.headache" />}
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  !!hdfData.symptom.find((sym: string) => sym === 'musclePain')
                }
                onChange={(e) => {
                  const checked = !!hdfData.symptom.find(
                    (sym: string) => sym === 'musclePain'
                  );
                  if (checked) {
                    setHdfData({
                      ...hdfData,
                      symptom: hdfData.symptom.filter(
                        (sym: string) => sym !== 'musclePain'
                      ),
                    });
                    return;
                  }
                  setHdfData({
                    ...hdfData,
                    symptom: [
                      ...hdfData.symptom.filter((sym: any) => sym !== 'no'),
                      'musclePain',
                    ],
                  });
                }}
                name="symptom"
                color="primary"
              />
            }
            label={<FormattedMessage id="hdf.symptom.musclePain" />}
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  !!hdfData.symptom.find((sym: string) => sym === 'runnyNose')
                }
                onChange={(e) => {
                  const checked = !!hdfData.symptom.find(
                    (sym: string) => sym === 'runnyNose'
                  );
                  if (checked) {
                    setHdfData({
                      ...hdfData,
                      symptom: hdfData.symptom.filter(
                        (sym: string) => sym !== 'runnyNose'
                      ),
                    });
                    return;
                  }
                  setHdfData({
                    ...hdfData,
                    symptom: [
                      ...hdfData.symptom.filter((sym: any) => sym !== 'no'),
                      'runnyNose',
                    ],
                  });
                }}
                name="symptom"
                color="primary"
              />
            }
            label={<FormattedMessage id="hdf.symptom.runnyNose" />}
            labelPlacement="end"
          />
        </Grid>

        <Grid item xs={6} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  !!hdfData.symptom.find((sym: string) => sym === 'soreThroat')
                }
                onChange={(e) => {
                  const checked = !!hdfData.symptom.find(
                    (sym: string) => sym === 'soreThroat'
                  );
                  if (checked) {
                    setHdfData({
                      ...hdfData,
                      symptom: hdfData.symptom.filter(
                        (sym: string) => sym !== 'soreThroat'
                      ),
                    });
                    return;
                  }
                  setHdfData({
                    ...hdfData,
                    symptom: [
                      ...hdfData.symptom.filter((sym: any) => sym !== 'no'),
                      'soreThroat',
                    ],
                  });
                }}
                name="symptom"
                color="primary"
              />
            }
            label={<FormattedMessage id="hdf.symptom.soreThroat" />}
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  !!hdfData.symptom.find((sym: string) => sym === 'stuffyNose')
                }
                onChange={(e) => {
                  const checked = !!hdfData.symptom.find(
                    (sym: string) => sym === 'stuffyNose'
                  );
                  if (checked) {
                    setHdfData({
                      ...hdfData,
                      symptom: hdfData.symptom.filter(
                        (sym: string) => sym !== 'stuffyNose'
                      ),
                    });
                    return;
                  }
                  setHdfData({
                    ...hdfData,
                    symptom: [
                      ...hdfData.symptom.filter((sym: any) => sym !== 'no'),
                      'stuffyNose',
                    ],
                  });
                }}
                name="symptom"
                color="primary"
              />
            }
            label={<FormattedMessage id="hdf.symptom.stuffyNose" />}
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  !!hdfData.symptom.find((sym: string) => sym === 'vomiting')
                }
                onChange={(e) => {
                  const checked = !!hdfData.symptom.find(
                    (sym: string) => sym === 'vomiting'
                  );
                  if (checked) {
                    setHdfData({
                      ...hdfData,
                      symptom: hdfData.symptom.filter(
                        (sym: string) => sym !== 'vomiting'
                      ),
                    });
                    return;
                  }
                  setHdfData({
                    ...hdfData,
                    symptom: [
                      ...hdfData.symptom.filter((sym: any) => sym !== 'no'),
                      'vomiting',
                    ],
                  });
                }}
                name="symptom"
                color="primary"
              />
            }
            label={<FormattedMessage id="hdf.symptom.vomiting" />}
            labelPlacement="end"
          />
        </Grid>

        {/* last 3 rows of questions */}
        <Grid
          item
          xs={12}
          sm={12}
          style={{ color: '#808080', margin: 'auto 0' }}
        >
          <FormattedMessage id="hdf.contact" />:
        </Grid>

        <Grid item xs={3} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormattedMessage id="hdf.contact.phone" />
          <sup style={{ color: 'red' }}>*</sup>:
        </Grid>
        <Grid item xs={7} sm={7} style={{ color: '#808080', margin: 'auto 0' }}>
          <TextField
            style={{ width: '14rem' }}
            error={!!errors['contactPhone']}
            label={errors['contactPhone']}
            name="contactPhone"
            value={hdfData.contactPhone}
            onChange={(e) => {
              const { name, value } = e.target;
              setHdfData({ ...hdfData, [name]: value });
            }}
          />
        </Grid>
        <Grid item xs={2} sm={2} style={{ color: '#808080', margin: 'auto 0' }}>
          {' '}
        </Grid>

        <Grid item xs={3} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormattedMessage id="hdf.email" />
          <sup style={{ color: 'red' }}>*</sup>:
        </Grid>
        <Grid item xs={7} sm={7} style={{ color: '#808080', margin: 'auto 0' }}>
          <TextField
            style={{ width: '14rem' }}
            error={!!errors['contactEmail']}
            label={errors['contactEmail']}
            name="contactEmail"
            value={hdfData.contactEmail}
            onChange={(e) => {
              const { name, value } = e.target;
              setHdfData({ ...hdfData, [name]: value });
            }}
          />
        </Grid>
        <Grid item xs={2} sm={2} style={{ color: '#808080', margin: 'auto 0' }}>
          {' '}
        </Grid>

        <Grid item xs={3} sm={3} style={{ color: '#808080', margin: 'auto 0' }}>
          <FormattedMessage id="hdf.address" />:
        </Grid>
        <Grid item xs={7} sm={7} style={{ color: '#808080', margin: 'auto 0' }}>
          <Input
            style={{ width: '14rem' }}
            name="contactAddress"
            value={hdfData.contactAddress}
            onChange={(e) => {
              const { name, value } = e.target;
              setHdfData({ ...hdfData, [name]: value });
            }}
          />
        </Grid>

        {/* submit button row */}
        <Grid item xs={4} sm={4} style={{ color: '#808080', margin: 'auto 0' }}>
          {' '}
        </Grid>
        <Grid item xs={6} sm={6} style={{ color: '#808080', margin: 'auto 0' }}>
          <Button
            style={{
              width: '50%',
              background: Primary,
              color: Secondary,
            }}
            variant="contained"
            onClick={async () => {
              /* check for required fields */
              const missing = checkRequiedFields(hdfData);
              // console.log({ missing, errors })
              if (missing) return;

              /* check if checked any symtoms || contact covid in 7 days */
              const highRisk = isHighRisk(hdfData);
              if (highRisk) {
                setHighRiskError(true);
                return;
              }

              /* if above check true, then sign and submit */
              setOpenSignature(true);
            }}
          >
            <FormattedMessage id="hdf.btn.signAndConfirm" />
          </Button>
        </Grid>
        <Grid item xs={2} sm={2} style={{ color: '#808080', margin: 'auto 0' }}>
          {' '}
        </Grid>
      </Grid>
    </div>
  );
};

const HighRiskPopup = (props: {
  highRiskError: boolean;
  setHighRiskError: Function;
}) => {
  const { highRiskError, setHighRiskError } = props;

  return (
    <>
      <div>
        <Dialog
          open={highRiskError}
          onClose={() => setHighRiskError(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage id="hdf.highRiskWarning.title" />
          </DialogTitle>
          <DialogContent>
            <FormattedMessage id="hdf.highRiskWarning.message" />
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                width: '50%',
                background: Primary,
                color: Secondary,
              }}
              onClick={() => setHighRiskError(false)}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

const SignaturePopup = (props: {
  data: any;
  openSignature: boolean;
  setOpenSignature: Function;
  hdfData: any;
  setHdfData: Function;
  submitHdfForm: Function;
  setIsFilling: Function;
}) => {
  const {
    data,
    openSignature,
    setOpenSignature,
    hdfData,
    setHdfData,
    submitHdfForm,
    setIsFilling,
  } = props;
  // const signaturePadRef: any = createRef<SignaturePad>();
  const signaturePadRef: any = createRef<SignatureCanvas>();

  const clearSignature = () => {
    const signaturePad = signaturePadRef.current;
    if (signaturePad) signaturePad.clear();
  };
  const setSignatureAndSubmit = async () => {
    const signaturePad = signaturePadRef.current;
    if (!signaturePad) return;
    if (signaturePad.isEmpty()) {
      // eslint-disable-next-line no-alert
      alert('Please provide a signature first.');
    } else {
      // window.open(signaturePad.toDataURL());
      const base64Signature = signaturePad
        .toDataURL()
        .split('image/png;base64,')[1];
      // console.log({ base64: base64Signature })

      /* post to /hdf */
      await submitHdfForm(base64Signature);
      /* refetch booking for getting the updated isfilled time */
      // const booking = await getCheckInBooking(data.confirmationNumber);
      // setData({
      //   ...booking,
      //   profileId: booking.profile.profileId,
      //   confirmationNumber: booking.confirmationNumber,
      //   firstName: booking.profile.firstName,
      //   lastName: booking.profile.lastName,
      //   address1: booking.profile.address.address1,
      //   address2: booking.profile.address.address2,
      //   country: booking.profile.address.countryCode,
      //   zipCode: booking.profile.address.zipCode ?? '',
      //   email: booking.profile.email.email,
      //   tel: booking.profile.phoneNumber.number,
      //   checkInDate: dayjs(booking.checkInDate).format('YYYY-MM-DD'),
      //   checkOutDate: dayjs(booking.checkOutDate).format('YYYY-MM-DD'),
      //   roomTypeCode: booking.roomTypeCode,
      //   billItems: booking.billItems,
      //   deposits: booking.deposits,
      //   isDepositPaid: booking.isDepositPaid,
      //   accompanyGuests: booking.accompanyGuests,
      // });
      setOpenSignature(false);
      setIsFilling(false);
    }
  };

  return (
    <>
      <div>
        <Dialog
          open={openSignature}
          onClose={() => setOpenSignature(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage id="hdf.sign.title" />
          </DialogTitle>
          <DialogContent>
            <div>
              <FormattedMessage id="hdf.sign.text" />{' '}
              <Button color="secondary" onClick={() => clearSignature()}>
                <FormattedMessage id="hdf.sign.clear" />
              </Button>
            </div>
            <div style={{ border: 'dashed' }}>
              <SignatureCanvas
                clearOnResize={true}
                ref={signaturePadRef}
                canvasProps={{
                  width: 550,
                  height: 200,
                  className: 'sigCanvas',
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                width: '50%',
                background: Primary,
                color: Secondary,
              }}
              onClick={() => setOpenSignature(false)}
              color="primary"
            >
              <FormattedMessage id="hdf.btn.back" />
            </Button>
            <Button
              style={{
                width: '50%',
                background: Primary,
                color: Secondary,
              }}
              variant="contained"
              onClick={async () => {
                await setSignatureAndSubmit();
              }}
              color="primary"
              autoFocus
            >
              <FormattedMessage id="hdf.btn.submitForm" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

/* Main HDF screen include the components <GuestList /> & the <HdfForm /> */
export const HdfLayout = () => {
  const [errors, setErrors] = useState({
    // countryVisited: null,
    dateOfBirth: null,
    // email: null,
    // entryPort: null,
    firstName: null,
    lastName: null,
    nationality: null,
    passport: null,
    symptom: '',
    contactPhone: null,
    contactEmail: null,
    historyOfContact: '',
    // nameTitle: null,
    sex: null,
  });

  const data = useSelector(selectBookingData);

  const hdfDataDefault = {
    address: `${data?.address1 || ''} ${data?.address2 || ''}`.trim() || '',
    arrivalDate: '',
    bookingNumber: data?.confirmationNumber || '',
    comeFrom: '',
    placesVisitedInSevenDays: '',
    compulsoryQuarantine: true,
    countryVisited: '',
    contactConfirmedCases: '',
    dateOfBirth: null,
    email: data?.email || '',
    entryPort: '',
    firstName: data?.firstName || '',
    flightNo: '',
    lastName: data?.lastName || '',
    name: `${data?.lastName || ''} ${data?.firstName || ''}` || '',
    nationality: '',
    passport: '',
    seatNo: '',
    symptom: [],
    symptomDays: 0,
    temperature: '36.5',
    historyOfContact: '',
    signature:
      ' iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII= ',

    contactPhone: data?.tel || '',
    contactEmail: data?.email || '',
    contactAddress:
      `${data?.address1 || ''} ${data?.address2 || ''}`.trim() || '',
  };

  const [hdfData, setHdfData] = useState(hdfDataDefault);
  // console.log({ data })
  const [isFilling, setIsFilling] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState({});
  const [openSignature, setOpenSignature] = useState(false);

  const classes = useStyles();
  // console.log(localStorage);
  // const confirmationNumber = localStorage.getItem('confirmationNumber') ?? '';
  // const email = localStorage.getItem('email') ?? '';
  let lang = localStorage.getItem('lang');

  if (lang === 'undefined' || lang === '' || lang === null) {
    lang = 'en';
  }

  const checkRequiedFields = (data: any): boolean => {
    const checkMissingFieldsByErrorKeys: any = Object.keys(errors).reduce(
      (acc, key) => {
        const errMsg =
          key === 'symptom' || key === 'historyOfContact'
            ? 'Please Review'
            : 'Required';
        if (Array.isArray(data[key])) {
          acc[key] = data[key]?.length !== 0 ? null : errMsg;
        } else {
          acc[key] = !!data[key] ? null : errMsg;
        }
        return acc;
      },
      {}
    );

    setErrors({ ...checkMissingFieldsByErrorKeys });

    return Object.values(checkMissingFieldsByErrorKeys).some((value) => value);
  };

  const isHighRisk = (data: any): boolean => {
    const { symptom, historyOfContact } = data;
    /* check has symtoms */
    const hasSymptom = symptom.filter((sym: string) => sym !== 'no');

    /* check historyOfContact is yes, */
    const hasContactInPast7Days = historyOfContact === 'yes';

    return hasSymptom.length > 0 || hasContactInPast7Days;
  };

  const submitHdfForm = async (base64Signature: string) => {
    const requestData = {
      ...hdfData,
      signature: base64Signature,
      historyOfContact: hdfData.historyOfContact === 'yes',
      phoneNumber: hdfData.contactPhone,
      address: hdfData.contactAddress,
      email: hdfData.contactEmail,
      dateOfBirthYYYY: dayjs(hdfData.dateOfBirth).format('YYYY'),
      dateOfBirthMM: dayjs(hdfData.dateOfBirth).format('MM'),
    };
    // console.log(JSON.stringify(requestData))

    const settings = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      timeout: 6000000,
    };
    const url = REACT_APP_API_URL + 'hdf';

    try {
      const res = await axios.post(url, requestData, settings);
      // console.log("/hdf response: ", res)

      /* reset hdfData */
      setHdfData(hdfDataDefault);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('post /hdf err:', error);
      }
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <h4>
          {' '}
          <FormattedMessage id="hdf.title" />{' '}
        </h4>
        {!isFilling ? (
          <GuestList
            {...{
              data,
              isFilling,
              setIsFilling,
              setSelectedGuest,
              hdfData,
              setHdfData,
            }}
          />
        ) : (
          <HdfForm
            {...{
              data,
              hdfData,
              setHdfData,
              setOpenSignature,
              isFilling,
              selectedGuest,
              setSelectedGuest,
              errors,
              checkRequiedFields,
              isHighRisk,
            }}
          />
        )}

        <SignaturePopup
          {...{
            data,
            openSignature,
            setIsFilling,
            setOpenSignature,
            hdfData,
            setHdfData,
            submitHdfForm,
          }}
        />
      </div>
    </Container>
  );
};
