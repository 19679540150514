import { IntlShape, useIntl } from 'react-intl';
import { getCountryCallingCode } from 'react-phone-number-input';

export const countryCode = [
  { key: 'none', value: '', id: 'reg.pleaseSelect' },
  { key: 'AF', value: 'AF', id: 'country.AF' },
  { key: 'AL', value: 'AL', id: 'country.AL' },
  { key: 'DZ', value: 'DZ', id: 'country.DZ' },
  { key: 'AS', value: 'AS', id: 'country.AS' },
  { key: 'AD', value: 'AD', id: 'country.AD' },
  { key: 'AO', value: 'AO', id: 'country.AO' },
  { key: 'AI', value: 'AI', id: 'country.AI' },
  { key: 'AQ', value: 'AQ', id: 'country.AQ' },
  { key: 'AG', value: 'AG', id: 'country.AG' },
  { key: 'AR', value: 'AR', id: 'country.AR' },
  { key: 'AM', value: 'AM', id: 'country.AM' },
  { key: 'AW', value: 'AW', id: 'country.AW' },
  { key: 'AU', value: 'AU', id: 'country.AU' },
  { key: 'AT', value: 'AT', id: 'country.AT' },
  { key: 'AZ', value: 'AZ', id: 'country.AZ' },
  { key: 'BS', value: 'BS', id: 'country.BS' },
  { key: 'BH', value: 'BH', id: 'country.BH' },
  { key: 'BD', value: 'BD', id: 'country.BD' },
  { key: 'BB', value: 'BB', id: 'country.BB' },
  { key: 'BC', value: 'BC', id: 'country.BC' },
  { key: 'BY', value: 'BY', id: 'country.BY' },
  { key: 'BE', value: 'BE', id: 'country.BE' },
  { key: 'BZ', value: 'BZ', id: 'country.BZ' },
  { key: 'BJ', value: 'BJ', id: 'country.BJ' },
  { key: 'BM', value: 'BM', id: 'country.BM' },
  { key: 'BT', value: 'BT', id: 'country.BT' },
  { key: 'BO', value: 'BO', id: 'country.BO' },
  { key: 'BQ', value: 'BQ', id: 'country.BQ' },
  { key: 'BA', value: 'BA', id: 'country.BA' },
  { key: 'BW', value: 'BW', id: 'country.BW' },
  { key: 'BV', value: 'BV', id: 'country.BV' },
  { key: 'BR', value: 'BR', id: 'country.BR' },
  { key: 'IO', value: 'IO', id: 'country.IO' },
  { key: 'BN', value: 'BN', id: 'country.BN' },
  { key: 'BG', value: 'BG', id: 'country.BG' },
  { key: 'BF', value: 'BF', id: 'country.BF' },
  { key: 'BI', value: 'BI', id: 'country.BI' },
  { key: 'KH', value: 'KH', id: 'country.KH' },
  { key: 'CM', value: 'CM', id: 'country.CM' },
  { key: 'CA', value: 'CA', id: 'country.CA' },
  { key: 'CV', value: 'CV', id: 'country.CV' },
  { key: 'KY', value: 'KY', id: 'country.KY' },
  { key: 'CF', value: 'CF', id: 'country.CF' },
  { key: 'TD', value: 'TD', id: 'country.TD' },
  { key: 'CL', value: 'CL', id: 'country.CL' },
  { key: 'CN', value: 'CN', id: 'country.CN' },
  { key: 'CX', value: 'CX', id: 'country.CX' },
  { key: 'CC', value: 'CC', id: 'country.CC' },
  { key: 'CO', value: 'CO', id: 'country.CO' },
  { key: 'KM', value: 'KM', id: 'country.KM' },
  { key: 'CG', value: 'CG', id: 'country.CG' },
  { key: 'CD', value: 'CD', id: 'country.CD' },
  { key: 'CK', value: 'CK', id: 'country.CK' },
  { key: 'CR', value: 'CR', id: 'country.CR' },
  { key: 'CI', value: 'CI', id: 'country.CI' },
  { key: 'HR', value: 'HR', id: 'country.HR' },
  { key: 'CU', value: 'CU', id: 'country.CU' },
  { key: 'CW', value: 'CW', id: 'country.CW' },
  { key: 'CY', value: 'CY', id: 'country.CY' },
  { key: 'CZ', value: 'CZ', id: 'country.CZ' },
  { key: 'DK', value: 'DK', id: 'country.DK' },
  { key: 'DJ', value: 'DJ', id: 'country.DJ' },
  { key: 'DM', value: 'DM', id: 'country.DM' },
  { key: 'DO', value: 'DO', id: 'country.DO' },
  { key: 'TP', value: 'TP', id: 'country.TP' },
  { key: 'EC', value: 'EC', id: 'country.EC' },
  { key: 'EG', value: 'EG', id: 'country.EG' },
  { key: 'SV', value: 'SV', id: 'country.SV' },
  { key: 'GQ', value: 'GQ', id: 'country.GQ' },
  { key: 'ER', value: 'ER', id: 'country.ER' },
  { key: 'EE', value: 'EE', id: 'country.EE' },
  { key: 'ET', value: 'ET', id: 'country.ET' },
  { key: 'FK', value: 'FK', id: 'country.FK' },
  { key: 'FO', value: 'FO', id: 'country.FO' },
  { key: 'FJ', value: 'FJ', id: 'country.FJ' },
  { key: 'FI', value: 'FI', id: 'country.FI' },
  { key: 'FS', value: 'FS', id: 'country.FS' },
  { key: 'FR', value: 'FR', id: 'country.FR' },
  { key: 'FX', value: 'FX', id: 'country.FX' },
  { key: 'GF', value: 'GF', id: 'country.GF' },
  { key: 'PF', value: 'PF', id: 'country.PF' },
  { key: 'TF', value: 'TF', id: 'country.TF' },
  { key: 'GA', value: 'GA', id: 'country.GA' },
  { key: 'GM', value: 'GM', id: 'country.GM' },
  { key: 'GE', value: 'GE', id: 'country.GE' },
  { key: 'DE', value: 'DE', id: 'country.DE' },
  { key: 'GH', value: 'GH', id: 'country.GH' },
  { key: 'GI', value: 'GI', id: 'country.GI' },
  { key: 'GB', value: 'GB', id: 'country.GB' },
  { key: 'GR', value: 'GR', id: 'country.GR' },
  { key: 'GL', value: 'GL', id: 'country.GL' },
  { key: 'GD', value: 'GD', id: 'country.GD' },
  { key: 'GP', value: 'GP', id: 'country.GP' },
  { key: 'GU', value: 'GU', id: 'country.GU' },
  { key: 'GZ', value: 'GZ', id: 'country.GZ' },
  { key: 'GT', value: 'GT', id: 'country.GT' },
  { key: 'GN', value: 'GN', id: 'country.GN' },
  { key: 'GW', value: 'GW', id: 'country.GW' },
  { key: 'GY', value: 'GY', id: 'country.GY' },
  { key: 'HT', value: 'HT', id: 'country.HT' },
  { key: 'HM', value: 'HM', id: 'country.HM' },
  { key: 'HN', value: 'HN', id: 'country.HN' },
  { key: 'HK', value: 'HK', id: 'country.HK' },
  { key: 'HU', value: 'HU', id: 'country.HU' },
  { key: 'IS', value: 'IS', id: 'country.IS' },
  { key: 'IN', value: 'IN', id: 'country.IN' },
  { key: 'ID', value: 'ID', id: 'country.ID' },
  { key: 'IR', value: 'IR', id: 'country.IR' },
  { key: 'IQ', value: 'IQ', id: 'country.IQ' },
  { key: 'IE', value: 'IE', id: 'country.IE' },
  { key: 'IM', value: 'IM', id: 'country.IM' },
  { key: 'IL', value: 'IL', id: 'country.IL' },
  { key: 'IT', value: 'IT', id: 'country.IT' },
  { key: 'JM', value: 'JM', id: 'country.JM' },
  { key: 'JP', value: 'JP', id: 'country.JP' },
  { key: 'JO', value: 'JO', id: 'country.JO' },
  { key: 'KZ', value: 'KZ', id: 'country.KZ' },
  { key: 'KE', value: 'KE', id: 'country.KE' },
  { key: 'KI', value: 'KI', id: 'country.KI' },
  { key: 'KR', value: 'KR', id: 'country.KR' },
  { key: 'KP', value: 'KP', id: 'country.KP' },
  { key: 'KW', value: 'KW', id: 'country.KW' },
  { key: 'KG', value: 'KG', id: 'country.KG' },
  { key: 'LA', value: 'LA', id: 'country.LA' },
  { key: 'LV', value: 'LV', id: 'country.LV' },
  { key: 'LB', value: 'LB', id: 'country.LB' },
  { key: 'LS', value: 'LS', id: 'country.LS' },
  { key: 'LR', value: 'LR', id: 'country.LR' },
  { key: 'LY', value: 'LY', id: 'country.LY' },
  { key: 'LI', value: 'LI', id: 'country.LI' },
  { key: 'LT', value: 'LT', id: 'country.LT' },
  { key: 'LU', value: 'LU', id: 'country.LU' },
  { key: 'MO', value: 'MO', id: 'country.MO' },
  { key: 'MK', value: 'MK', id: 'country.MK' },
  { key: 'MG', value: 'MG', id: 'country.MG' },
  { key: 'MW', value: 'MW', id: 'country.MW' },
  { key: 'MY', value: 'MY', id: 'country.MY' },
  { key: 'MV', value: 'MV', id: 'country.MV' },
  { key: 'ML', value: 'ML', id: 'country.ML' },
  { key: 'MT', value: 'MT', id: 'country.MT' },
  { key: 'MH', value: 'MH', id: 'country.MH' },
  { key: 'MQ', value: 'MQ', id: 'country.MQ' },
  { key: 'MR', value: 'MR', id: 'country.MR' },
  { key: 'MU', value: 'MU', id: 'country.MU' },
  { key: 'YT', value: 'YT', id: 'country.YT' },
  { key: 'MX', value: 'MX', id: 'country.MX' },
  { key: 'FM', value: 'FM', id: 'country.FM' },
  { key: 'MI', value: 'MI', id: 'country.MI' },
  { key: 'MD', value: 'MD', id: 'country.MD' },
  { key: 'MC', value: 'MC', id: 'country.MC' },
  { key: 'MN', value: 'MN', id: 'country.MN' },
  { key: 'ME', value: 'ME', id: 'country.ME' },
  { key: 'MS', value: 'MS', id: 'country.MS' },
  { key: 'MA', value: 'MA', id: 'country.MA' },
  { key: 'MZ', value: 'MZ', id: 'country.MZ' },
  { key: 'MM', value: 'MM', id: 'country.MM' },
  { key: 'NA', value: 'NA', id: 'country.NA' },
  { key: 'NR', value: 'NR', id: 'country.NR' },
  { key: 'NP', value: 'NP', id: 'country.NP' },
  { key: 'NL', value: 'NL', id: 'country.NL' },
  { key: 'AN', value: 'AN', id: 'country.AN' },
  { key: 'NC', value: 'NC', id: 'country.NC' },
  { key: 'NZ', value: 'NZ', id: 'country.NZ' },
  { key: 'NI', value: 'NI', id: 'country.NI' },
  { key: 'NE', value: 'NE', id: 'country.NE' },
  { key: 'NG', value: 'NG', id: 'country.NG' },
  { key: 'NU', value: 'NU', id: 'country.NU' },
  { key: 'NF', value: 'NF', id: 'country.NF' },
  { key: 'MP', value: 'MP', id: 'country.MP' },
  { key: 'NO', value: 'NO', id: 'country.NO' },
  { key: 'OM', value: 'OM', id: 'country.OM' },
  { key: 'PK', value: 'PK', id: 'country.PK' },
  { key: 'PW', value: 'PW', id: 'country.PW' },
  { key: 'PA', value: 'PA', id: 'country.PA' },
  { key: 'PG', value: 'PG', id: 'country.PG' },
  { key: 'PY', value: 'PY', id: 'country.PY' },
  { key: 'PE', value: 'PE', id: 'country.PE' },
  { key: 'PH', value: 'PH', id: 'country.PH' },
  { key: 'PN', value: 'PN', id: 'country.PN' },
  { key: 'PL', value: 'PL', id: 'country.PL' },
  { key: 'PT', value: 'PT', id: 'country.PT' },
  { key: 'PR', value: 'PR', id: 'country.PR' },
  { key: 'QA', value: 'QA', id: 'country.QA' },
  { key: 'RE', value: 'RE', id: 'country.RE' },
  { key: 'RO', value: 'RO', id: 'country.RO' },
  { key: 'RU', value: 'RU', id: 'country.RU' },
  { key: 'RQ', value: 'RQ', id: 'country.RQ' },
  { key: 'RW', value: 'RW', id: 'country.RW' },
  { key: 'SH', value: 'SH', id: 'country.SH' },
  { key: 'KN', value: 'KN', id: 'country.KN' },
  { key: 'LC', value: 'LC', id: 'country.LC' },
  { key: 'PM', value: 'PM', id: 'country.PM' },
  { key: 'VC', value: 'VC', id: 'country.VC' },
  { key: 'WS', value: 'WS', id: 'country.WS' },
  { key: 'SM', value: 'SM', id: 'country.SM' },
  { key: 'ST', value: 'ST', id: 'country.ST' },
  { key: 'SA', value: 'SA', id: 'country.SA' },
  { key: 'SN', value: 'SN', id: 'country.SN' },
  { key: 'RS', value: 'RS', id: 'country.RS' },
  { key: 'SC', value: 'SC', id: 'country.SC' },
  { key: 'SS', value: 'SS', id: 'country.SS' },
  { key: 'SX', value: 'SX', id: 'country.SX' },
  { key: 'SL', value: 'SL', id: 'country.SL' },
  { key: 'SG', value: 'SG', id: 'country.SG' },
  { key: 'SK', value: 'SK', id: 'country.SK' },
  { key: 'SI', value: 'SI', id: 'country.SI' },
  { key: 'SB', value: 'SB', id: 'country.SB' },
  { key: 'SO', value: 'SO', id: 'country.SO' },
  { key: 'ZA', value: 'ZA', id: 'country.ZA' },
  { key: 'GS', value: 'GS', id: 'country.GS' },
  { key: 'ES', value: 'ES', id: 'country.ES' },
  { key: 'LK', value: 'LK', id: 'country.LK' },
  { key: 'SD', value: 'SD', id: 'country.SD' },
  { key: 'SR', value: 'SR', id: 'country.SR' },
  { key: 'SJ', value: 'SJ', id: 'country.SJ' },
  { key: 'SZ', value: 'SZ', id: 'country.SZ' },
  { key: 'SE', value: 'SE', id: 'country.SE' },
  { key: 'CH', value: 'CH', id: 'country.CH' },
  { key: 'SY', value: 'SY', id: 'country.SY' },
  { key: 'TW', value: 'TW', id: 'country.TW' },
  { key: 'TJ', value: 'TJ', id: 'country.TJ' },
  { key: 'TZ', value: 'TZ', id: 'country.TZ' },
  { key: 'TH', value: 'TH', id: 'country.TH' },
  { key: 'TG', value: 'TG', id: 'country.TG' },
  { key: 'TK', value: 'TK', id: 'country.TK' },
  { key: 'TO', value: 'TO', id: 'country.TO' },
  { key: 'TT', value: 'TT', id: 'country.TT' },
  { key: 'TN', value: 'TN', id: 'country.TN' },
  { key: 'TR', value: 'TR', id: 'country.TR' },
  { key: 'TM', value: 'TM', id: 'country.TM' },
  { key: 'TC', value: 'TC', id: 'country.TC' },
  { key: 'TV', value: 'TV', id: 'country.TV' },
  { key: 'UG', value: 'UG', id: 'country.UG' },
  { key: 'UA', value: 'UA', id: 'country.UA' },
  { key: 'AE', value: 'AE', id: 'country.AE' },
  { key: 'UK', value: 'UK', id: 'country.UK' },
  { key: 'US', value: 'US', id: 'country.US' },
  { key: 'UM', value: 'UM', id: 'country.UM' },
  { key: 'XX', value: 'XX', id: 'country.XX' },
  { key: 'UY', value: 'UY', id: 'country.UY' },
  { key: 'UZ', value: 'UZ', id: 'country.UZ' },
  { key: 'VU', value: 'VU', id: 'country.VU' },
  { key: 'VA', value: 'VA', id: 'country.VA' },
  { key: 'VE', value: 'VE', id: 'country.VE' },
  { key: 'VN', value: 'VN', id: 'country.VN' },
  { key: 'VG', value: 'VG', id: 'country.VG' },
  { key: 'VI', value: 'VI', id: 'country.VI' },
  { key: 'WF', value: 'WF', id: 'country.WF' },
  { key: 'EH', value: 'EH', id: 'country.EH' },
  { key: 'YE', value: 'YE', id: 'country.YE' },
  { key: 'ZR', value: 'ZR', id: 'country.ZR' },
  { key: 'ZM', value: 'ZM', id: 'country.ZM' },
  { key: 'ZW', value: 'ZW', id: 'country.ZW' },
];

export const deviceTypes = [
  'gsr',
  'prearrival',
  'remote',
  'esign',
  'admin',
  'she',
  'ldapauth',
  'paymentRedirect',
] as const;
export type DeviceTypes = (typeof deviceTypes)[number];

export const getPhonePlaceholder = (intl: IntlShape) => {
  return {
    AF:
      intl.formatMessage({ id: 'country.AF' }) +
      '  +' +
      getCountryCallingCode('AF'),
    AX:
      intl.formatMessage({ id: 'country.AX' }) +
      '  +' +
      getCountryCallingCode('AX'),
    AL:
      intl.formatMessage({ id: 'country.AL' }) +
      '  +' +
      getCountryCallingCode('AL'),
    DZ:
      intl.formatMessage({ id: 'country.DZ' }) +
      '  +' +
      getCountryCallingCode('DZ'),
    AS:
      intl.formatMessage({ id: 'country.AS' }) +
      '  +' +
      getCountryCallingCode('AS'),
    AD:
      intl.formatMessage({ id: 'country.AD' }) +
      '  +' +
      getCountryCallingCode('AD'),
    AO:
      intl.formatMessage({ id: 'country.AO' }) +
      '  +' +
      getCountryCallingCode('AO'),
    AI:
      intl.formatMessage({ id: 'country.AI' }) +
      '  +' +
      getCountryCallingCode('AI'),
    AQ: intl.formatMessage({ id: 'country.AQ' }) + '  +672',
    AG:
      intl.formatMessage({ id: 'country.AG' }) +
      '  +' +
      getCountryCallingCode('AG'),
    AR:
      intl.formatMessage({ id: 'country.AR' }) +
      '  +' +
      getCountryCallingCode('AR'),
    AM:
      intl.formatMessage({ id: 'country.AM' }) +
      '  +' +
      getCountryCallingCode('AM'),
    AW:
      intl.formatMessage({ id: 'country.AW' }) +
      '  +' +
      getCountryCallingCode('AW'),
    AC:
      intl.formatMessage({ id: 'country.AC' }) +
      '  +' +
      getCountryCallingCode('AC'),
    AU:
      intl.formatMessage({ id: 'country.AU' }) +
      '  +' +
      getCountryCallingCode('AU'),
    AT:
      intl.formatMessage({ id: 'country.AT' }) +
      '  +' +
      getCountryCallingCode('AT'),
    AZ:
      intl.formatMessage({ id: 'country.AZ' }) +
      '  +' +
      getCountryCallingCode('AZ'),
    BS:
      intl.formatMessage({ id: 'country.BS' }) +
      '  +' +
      getCountryCallingCode('BS'),
    BH:
      intl.formatMessage({ id: 'country.BH' }) +
      '  +' +
      getCountryCallingCode('BH'),
    BD:
      intl.formatMessage({ id: 'country.BD' }) +
      '  +' +
      getCountryCallingCode('BD'),
    BB:
      intl.formatMessage({ id: 'country.BB' }) +
      '  +' +
      getCountryCallingCode('BB'),
    BY:
      intl.formatMessage({ id: 'country.BY' }) +
      '  +' +
      getCountryCallingCode('BY'),
    BE:
      intl.formatMessage({ id: 'country.BE' }) +
      '  +' +
      getCountryCallingCode('BE'),
    BZ:
      intl.formatMessage({ id: 'country.BZ' }) +
      '  +' +
      getCountryCallingCode('BZ'),
    BJ:
      intl.formatMessage({ id: 'country.BJ' }) +
      '  +' +
      getCountryCallingCode('BJ'),
    BM:
      intl.formatMessage({ id: 'country.BM' }) +
      '  +' +
      getCountryCallingCode('BM'),
    BT:
      intl.formatMessage({ id: 'country.BT' }) +
      '  +' +
      getCountryCallingCode('BT'),
    BO:
      intl.formatMessage({ id: 'country.BO' }) +
      '  +' +
      getCountryCallingCode('BO'),
    BQ:
      intl.formatMessage({ id: 'country.BQ' }) +
      '  +' +
      getCountryCallingCode('BQ'),
    BA:
      intl.formatMessage({ id: 'country.BA' }) +
      '  +' +
      getCountryCallingCode('BA'),
    BW:
      intl.formatMessage({ id: 'country.BW' }) +
      '  +' +
      getCountryCallingCode('BW'),
    BV: intl.formatMessage({ id: 'country.BV' }) + '  +55',
    BR:
      intl.formatMessage({ id: 'country.BR' }) +
      '  +' +
      getCountryCallingCode('BR'),
    IO:
      intl.formatMessage({ id: 'country.IO' }) +
      '  +' +
      getCountryCallingCode('IO'),
    BN:
      intl.formatMessage({ id: 'country.BN' }) +
      '  +' +
      getCountryCallingCode('BN'),
    BG:
      intl.formatMessage({ id: 'country.BG' }) +
      '  +' +
      getCountryCallingCode('BG'),
    BF:
      intl.formatMessage({ id: 'country.BF' }) +
      '  +' +
      getCountryCallingCode('BF'),
    BI:
      intl.formatMessage({ id: 'country.BI' }) +
      '  +' +
      getCountryCallingCode('BI'),
    KH:
      intl.formatMessage({ id: 'country.KH' }) +
      '  +' +
      getCountryCallingCode('KH'),
    CM:
      intl.formatMessage({ id: 'country.CM' }) +
      '  +' +
      getCountryCallingCode('CM'),
    CA:
      intl.formatMessage({ id: 'country.CA' }) +
      '  +' +
      getCountryCallingCode('CA'),
    CV:
      intl.formatMessage({ id: 'country.CV' }) +
      '  +' +
      getCountryCallingCode('CV'),
    KY:
      intl.formatMessage({ id: 'country.KY' }) +
      '  +' +
      getCountryCallingCode('KY'),
    CF:
      intl.formatMessage({ id: 'country.CF' }) +
      '  +' +
      getCountryCallingCode('CF'),
    TD:
      intl.formatMessage({ id: 'country.TD' }) +
      '  +' +
      getCountryCallingCode('TD'),
    CL:
      intl.formatMessage({ id: 'country.CL' }) +
      '  +' +
      getCountryCallingCode('CL'),
    CN:
      intl.formatMessage({ id: 'country.CN' }) +
      '  +' +
      getCountryCallingCode('CN'),
    CX:
      intl.formatMessage({ id: 'country.CX' }) +
      '  +' +
      getCountryCallingCode('CX'),
    CC:
      intl.formatMessage({ id: 'country.CC' }) +
      '  +' +
      getCountryCallingCode('CC'),
    CO:
      intl.formatMessage({ id: 'country.CO' }) +
      '  +' +
      getCountryCallingCode('CO'),
    KM:
      intl.formatMessage({ id: 'country.KM' }) +
      '  +' +
      getCountryCallingCode('KM'),
    CG:
      intl.formatMessage({ id: 'country.CG' }) +
      '  +' +
      getCountryCallingCode('CG'),
    CD:
      intl.formatMessage({ id: 'country.CD' }) +
      '  +' +
      getCountryCallingCode('CD'),
    CK:
      intl.formatMessage({ id: 'country.CK' }) +
      '  +' +
      getCountryCallingCode('CK'),
    CR:
      intl.formatMessage({ id: 'country.CR' }) +
      '  +' +
      getCountryCallingCode('CR'),
    CI:
      intl.formatMessage({ id: 'country.CI' }) +
      '  +' +
      getCountryCallingCode('CI'),
    HR:
      intl.formatMessage({ id: 'country.HR' }) +
      '  +' +
      getCountryCallingCode('HR'),
    CU:
      intl.formatMessage({ id: 'country.CU' }) +
      '  +' +
      getCountryCallingCode('CU'),
    CW:
      intl.formatMessage({ id: 'country.CW' }) +
      '  +' +
      getCountryCallingCode('CW'),
    CY:
      intl.formatMessage({ id: 'country.CY' }) +
      '  +' +
      getCountryCallingCode('CY'),
    CZ:
      intl.formatMessage({ id: 'country.CZ' }) +
      '  +' +
      getCountryCallingCode('CZ'),
    DK:
      intl.formatMessage({ id: 'country.DK' }) +
      '  +' +
      getCountryCallingCode('DK'),
    DJ:
      intl.formatMessage({ id: 'country.DJ' }) +
      '  +' +
      getCountryCallingCode('DJ'),
    DM:
      intl.formatMessage({ id: 'country.DM' }) +
      '  +' +
      getCountryCallingCode('DM'),
    DO:
      intl.formatMessage({ id: 'country.DO' }) +
      '  +' +
      getCountryCallingCode('DO'),
    EC:
      intl.formatMessage({ id: 'country.EC' }) +
      '  +' +
      getCountryCallingCode('EC'),
    EG:
      intl.formatMessage({ id: 'country.EG' }) +
      '  +' +
      getCountryCallingCode('EG'),
    SV:
      intl.formatMessage({ id: 'country.SV' }) +
      '  +' +
      getCountryCallingCode('SV'),
    GQ:
      intl.formatMessage({ id: 'country.GQ' }) +
      '  +' +
      getCountryCallingCode('GQ'),
    ER:
      intl.formatMessage({ id: 'country.ER' }) +
      '  +' +
      getCountryCallingCode('ER'),
    EE:
      intl.formatMessage({ id: 'country.EE' }) +
      '  +' +
      getCountryCallingCode('EE'),
    ET:
      intl.formatMessage({ id: 'country.ET' }) +
      '  +' +
      getCountryCallingCode('ET'),
    FK:
      intl.formatMessage({ id: 'country.FK' }) +
      '  +' +
      getCountryCallingCode('FK'),
    FO:
      intl.formatMessage({ id: 'country.FO' }) +
      '  +' +
      getCountryCallingCode('FO'),
    FJ:
      intl.formatMessage({ id: 'country.FJ' }) +
      '  +' +
      getCountryCallingCode('FJ'),
    FI:
      intl.formatMessage({ id: 'country.FI' }) +
      '  +' +
      getCountryCallingCode('FI'),
    FR:
      intl.formatMessage({ id: 'country.FR' }) +
      '  +' +
      getCountryCallingCode('FR'),
    GF:
      intl.formatMessage({ id: 'country.GF' }) +
      '  +' +
      getCountryCallingCode('GF'),
    PF:
      intl.formatMessage({ id: 'country.PF' }) +
      '  +' +
      getCountryCallingCode('PF'),
    TF: intl.formatMessage({ id: 'country.TF' }) + '  +262',
    GA:
      intl.formatMessage({ id: 'country.GA' }) +
      '  +' +
      getCountryCallingCode('GA'),
    GM:
      intl.formatMessage({ id: 'country.GM' }) +
      '  +' +
      getCountryCallingCode('GM'),
    GE:
      intl.formatMessage({ id: 'country.GE' }) +
      '  +' +
      getCountryCallingCode('GE'),
    DE:
      intl.formatMessage({ id: 'country.DE' }) +
      '  +' +
      getCountryCallingCode('DE'),
    GH:
      intl.formatMessage({ id: 'country.GH' }) +
      '  +' +
      getCountryCallingCode('GH'),
    GI:
      intl.formatMessage({ id: 'country.GI' }) +
      '  +' +
      getCountryCallingCode('GI'),
    GR:
      intl.formatMessage({ id: 'country.GR' }) +
      '  +' +
      getCountryCallingCode('GR'),
    GL:
      intl.formatMessage({ id: 'country.GL' }) +
      '  +' +
      getCountryCallingCode('GL'),
    GD:
      intl.formatMessage({ id: 'country.GD' }) +
      '  +' +
      getCountryCallingCode('GD'),
    GP:
      intl.formatMessage({ id: 'country.GP' }) +
      '  +' +
      getCountryCallingCode('GP'),
    GU:
      intl.formatMessage({ id: 'country.GU' }) +
      '  +' +
      getCountryCallingCode('GU'),
    GT:
      intl.formatMessage({ id: 'country.GT' }) +
      '  +' +
      getCountryCallingCode('GT'),
    GG:
      intl.formatMessage({ id: 'country.GG' }) +
      '  +' +
      getCountryCallingCode('GG'),
    GN:
      intl.formatMessage({ id: 'country.GN' }) +
      '  +' +
      getCountryCallingCode('GN'),
    GW:
      intl.formatMessage({ id: 'country.GW' }) +
      '  +' +
      getCountryCallingCode('GW'),
    GY:
      intl.formatMessage({ id: 'country.GY' }) +
      '  +' +
      getCountryCallingCode('GY'),
    HT:
      intl.formatMessage({ id: 'country.HT' }) +
      '  +' +
      getCountryCallingCode('HT'),
    HM: intl.formatMessage({ id: 'country.HM' }) + '  +672',
    VA:
      intl.formatMessage({ id: 'country.VA' }) +
      '  +' +
      getCountryCallingCode('VA'),
    HN:
      intl.formatMessage({ id: 'country.HN' }) +
      '  +' +
      getCountryCallingCode('HN'),
    HK:
      intl.formatMessage({ id: 'country.HK' }) +
      '  +' +
      getCountryCallingCode('HK'),
    HU:
      intl.formatMessage({ id: 'country.HU' }) +
      '  +' +
      getCountryCallingCode('HU'),
    IS:
      intl.formatMessage({ id: 'country.IS' }) +
      '  +' +
      getCountryCallingCode('IS'),
    IN:
      intl.formatMessage({ id: 'country.IN' }) +
      '  +' +
      getCountryCallingCode('IN'),
    ID:
      intl.formatMessage({ id: 'country.ID' }) +
      '  +' +
      getCountryCallingCode('ID'),
    IR:
      intl.formatMessage({ id: 'country.IR' }) +
      '  +' +
      getCountryCallingCode('IR'),
    IQ:
      intl.formatMessage({ id: 'country.IQ' }) +
      '  +' +
      getCountryCallingCode('IQ'),
    IE:
      intl.formatMessage({ id: 'country.IE' }) +
      '  +' +
      getCountryCallingCode('IE'),
    IM:
      intl.formatMessage({ id: 'country.IM' }) +
      '  +' +
      getCountryCallingCode('IM'),
    IL:
      intl.formatMessage({ id: 'country.IL' }) +
      '  +' +
      getCountryCallingCode('IL'),
    IT:
      intl.formatMessage({ id: 'country.IT' }) +
      '  +' +
      getCountryCallingCode('IT'),
    JM:
      intl.formatMessage({ id: 'country.JM' }) +
      '  +' +
      getCountryCallingCode('JM'),
    JP:
      intl.formatMessage({ id: 'country.JP' }) +
      '  +' +
      getCountryCallingCode('JP'),
    JE:
      intl.formatMessage({ id: 'country.JE' }) +
      '  +' +
      getCountryCallingCode('JE'),
    JO:
      intl.formatMessage({ id: 'country.JO' }) +
      '  +' +
      getCountryCallingCode('JO'),
    KZ:
      intl.formatMessage({ id: 'country.KZ' }) +
      '  +' +
      getCountryCallingCode('KZ'),
    KE:
      intl.formatMessage({ id: 'country.KE' }) +
      '  +' +
      getCountryCallingCode('KE'),
    KI:
      intl.formatMessage({ id: 'country.KI' }) +
      '  +' +
      getCountryCallingCode('KI'),
    KP:
      intl.formatMessage({ id: 'country.KP' }) +
      '  +' +
      getCountryCallingCode('KP'),
    KR:
      intl.formatMessage({ id: 'country.KR' }) +
      '  +' +
      getCountryCallingCode('KR'),
    KW:
      intl.formatMessage({ id: 'country.KW' }) +
      '  +' +
      getCountryCallingCode('KW'),
    KG:
      intl.formatMessage({ id: 'country.KG' }) +
      '  +' +
      getCountryCallingCode('KG'),
    LA:
      intl.formatMessage({ id: 'country.LA' }) +
      '  +' +
      getCountryCallingCode('LA'),
    LV:
      intl.formatMessage({ id: 'country.LV' }) +
      '  +' +
      getCountryCallingCode('LV'),
    LB:
      intl.formatMessage({ id: 'country.LB' }) +
      '  +' +
      getCountryCallingCode('LB'),
    LS:
      intl.formatMessage({ id: 'country.LS' }) +
      '  +' +
      getCountryCallingCode('LS'),
    LR:
      intl.formatMessage({ id: 'country.LR' }) +
      '  +' +
      getCountryCallingCode('LR'),
    LY:
      intl.formatMessage({ id: 'country.LY' }) +
      '  +' +
      getCountryCallingCode('LY'),
    LI:
      intl.formatMessage({ id: 'country.LI' }) +
      '  +' +
      getCountryCallingCode('LI'),
    LT:
      intl.formatMessage({ id: 'country.LT' }) +
      '  +' +
      getCountryCallingCode('LT'),
    LU:
      intl.formatMessage({ id: 'country.LU' }) +
      '  +' +
      getCountryCallingCode('LU'),
    MO:
      intl.formatMessage({ id: 'country.MO' }) +
      '  +' +
      getCountryCallingCode('MO'),
    MK:
      intl.formatMessage({ id: 'country.MK' }) +
      '  +' +
      getCountryCallingCode('MK'),
    MG:
      intl.formatMessage({ id: 'country.MG' }) +
      '  +' +
      getCountryCallingCode('MG'),
    MW:
      intl.formatMessage({ id: 'country.MW' }) +
      '  +' +
      getCountryCallingCode('MW'),
    MY:
      intl.formatMessage({ id: 'country.MY' }) +
      '  +' +
      getCountryCallingCode('MY'),
    MV:
      intl.formatMessage({ id: 'country.MV' }) +
      '  +' +
      getCountryCallingCode('MV'),
    ML:
      intl.formatMessage({ id: 'country.ML' }) +
      '  +' +
      getCountryCallingCode('ML'),
    MT:
      intl.formatMessage({ id: 'country.MT' }) +
      '  +' +
      getCountryCallingCode('MT'),
    MH:
      intl.formatMessage({ id: 'country.MH' }) +
      '  +' +
      getCountryCallingCode('MH'),
    MQ:
      intl.formatMessage({ id: 'country.MQ' }) +
      '  +' +
      getCountryCallingCode('MQ'),
    MR:
      intl.formatMessage({ id: 'country.MR' }) +
      '  +' +
      getCountryCallingCode('MR'),
    MU:
      intl.formatMessage({ id: 'country.MU' }) +
      '  +' +
      getCountryCallingCode('MU'),
    YT:
      intl.formatMessage({ id: 'country.YT' }) +
      '  +' +
      getCountryCallingCode('YT'),
    MX:
      intl.formatMessage({ id: 'country.MX' }) +
      '  +' +
      getCountryCallingCode('MX'),
    FM:
      intl.formatMessage({ id: 'country.FM' }) +
      '  +' +
      getCountryCallingCode('FM'),
    MD:
      intl.formatMessage({ id: 'country.MD' }) +
      '  +' +
      getCountryCallingCode('MD'),
    MC:
      intl.formatMessage({ id: 'country.MC' }) +
      '  +' +
      getCountryCallingCode('MC'),
    MN:
      intl.formatMessage({ id: 'country.MN' }) +
      '  +' +
      getCountryCallingCode('MN'),
    ME:
      intl.formatMessage({ id: 'country.ME' }) +
      '  +' +
      getCountryCallingCode('ME'),
    MS:
      intl.formatMessage({ id: 'country.MS' }) +
      '  +' +
      getCountryCallingCode('MS'),
    MA:
      intl.formatMessage({ id: 'country.MA' }) +
      '  +' +
      getCountryCallingCode('MA'),
    MZ:
      intl.formatMessage({ id: 'country.MZ' }) +
      '  +' +
      getCountryCallingCode('MZ'),
    MM:
      intl.formatMessage({ id: 'country.MM' }) +
      '  +' +
      getCountryCallingCode('MM'),
    NA:
      intl.formatMessage({ id: 'country.NA' }) +
      '  +' +
      getCountryCallingCode('NA'),
    NR:
      intl.formatMessage({ id: 'country.NR' }) +
      '  +' +
      getCountryCallingCode('NR'),
    NP:
      intl.formatMessage({ id: 'country.NP' }) +
      '  +' +
      getCountryCallingCode('NP'),
    NL:
      intl.formatMessage({ id: 'country.NL' }) +
      '  +' +
      getCountryCallingCode('NL'),
    NC:
      intl.formatMessage({ id: 'country.NC' }) +
      '  +' +
      getCountryCallingCode('NC'),
    NZ:
      intl.formatMessage({ id: 'country.NZ' }) +
      '  +' +
      getCountryCallingCode('NZ'),
    NI:
      intl.formatMessage({ id: 'country.NI' }) +
      '  +' +
      getCountryCallingCode('NI'),
    NE:
      intl.formatMessage({ id: 'country.NE' }) +
      '  +' +
      getCountryCallingCode('NE'),
    NG:
      intl.formatMessage({ id: 'country.NG' }) +
      '  +' +
      getCountryCallingCode('NG'),
    NU:
      intl.formatMessage({ id: 'country.NU' }) +
      '  +' +
      getCountryCallingCode('NU'),
    NF:
      intl.formatMessage({ id: 'country.NF' }) +
      '  +' +
      getCountryCallingCode('NF'),
    MP:
      intl.formatMessage({ id: 'country.MP' }) +
      '  +' +
      getCountryCallingCode('MP'),
    NO:
      intl.formatMessage({ id: 'country.NO' }) +
      '  +' +
      getCountryCallingCode('NO'),
    OM:
      intl.formatMessage({ id: 'country.OM' }) +
      '  +' +
      getCountryCallingCode('OM'),
    PK:
      intl.formatMessage({ id: 'country.PK' }) +
      '  +' +
      getCountryCallingCode('PK'),
    PW:
      intl.formatMessage({ id: 'country.PW' }) +
      '  +' +
      getCountryCallingCode('PW'),
    PS:
      intl.formatMessage({ id: 'country.PS' }) +
      '  +' +
      getCountryCallingCode('PS'),
    PA:
      intl.formatMessage({ id: 'country.PA' }) +
      '  +' +
      getCountryCallingCode('PA'),
    PG:
      intl.formatMessage({ id: 'country.PG' }) +
      '  +' +
      getCountryCallingCode('PG'),
    PY:
      intl.formatMessage({ id: 'country.PY' }) +
      '  +' +
      getCountryCallingCode('PY'),
    PE:
      intl.formatMessage({ id: 'country.PE' }) +
      '  +' +
      getCountryCallingCode('PE'),
    PH:
      intl.formatMessage({ id: 'country.PH' }) +
      '  +' +
      getCountryCallingCode('PH'),
    PN: intl.formatMessage({ id: 'country.PN' }) + '  +64',
    PL:
      intl.formatMessage({ id: 'country.PL' }) +
      '  +' +
      getCountryCallingCode('PL'),
    PT:
      intl.formatMessage({ id: 'country.PT' }) +
      '  +' +
      getCountryCallingCode('PT'),
    PR:
      intl.formatMessage({ id: 'country.PR' }) +
      '  +' +
      getCountryCallingCode('PR'),
    QA:
      intl.formatMessage({ id: 'country.QA' }) +
      '  +' +
      getCountryCallingCode('QA'),
    RE:
      intl.formatMessage({ id: 'country.RE' }) +
      '  +' +
      getCountryCallingCode('RE'),
    RO:
      intl.formatMessage({ id: 'country.RO' }) +
      '  +' +
      getCountryCallingCode('RO'),
    RU:
      intl.formatMessage({ id: 'country.RU' }) +
      '  +' +
      getCountryCallingCode('RU'),
    RW:
      intl.formatMessage({ id: 'country.RW' }) +
      '  +' +
      getCountryCallingCode('RW'),
    BL:
      intl.formatMessage({ id: 'country.BL' }) +
      '  +' +
      getCountryCallingCode('BL'),
    SH:
      intl.formatMessage({ id: 'country.SH' }) +
      '  +' +
      getCountryCallingCode('SH'),
    KN:
      intl.formatMessage({ id: 'country.KN' }) +
      '  +' +
      getCountryCallingCode('KN'),
    LC:
      intl.formatMessage({ id: 'country.LC' }) +
      '  +' +
      getCountryCallingCode('LC'),
    MF:
      intl.formatMessage({ id: 'country.MF' }) +
      '  +' +
      getCountryCallingCode('MF'),
    PM:
      intl.formatMessage({ id: 'country.PM' }) +
      '  +' +
      getCountryCallingCode('PM'),
    VC:
      intl.formatMessage({ id: 'country.VC' }) +
      '  +' +
      getCountryCallingCode('VC'),
    WS:
      intl.formatMessage({ id: 'country.WS' }) +
      '  +' +
      getCountryCallingCode('WS'),
    XK:
      intl.formatMessage({ id: 'country.XK' }) +
      '  +' +
      getCountryCallingCode('XK'),
    SM:
      intl.formatMessage({ id: 'country.SM' }) +
      '  +' +
      getCountryCallingCode('SM'),
    ST:
      intl.formatMessage({ id: 'country.ST' }) +
      '  +' +
      getCountryCallingCode('ST'),
    SA:
      intl.formatMessage({ id: 'country.SA' }) +
      '  +' +
      getCountryCallingCode('SA'),
    SN:
      intl.formatMessage({ id: 'country.SN' }) +
      '  +' +
      getCountryCallingCode('SN'),
    RS:
      intl.formatMessage({ id: 'country.RS' }) +
      '  +' +
      getCountryCallingCode('RS'),
    SC:
      intl.formatMessage({ id: 'country.SC' }) +
      '  +' +
      getCountryCallingCode('SC'),
    SL:
      intl.formatMessage({ id: 'country.SL' }) +
      '  +' +
      getCountryCallingCode('SL'),
    SG:
      intl.formatMessage({ id: 'country.SG' }) +
      '  +' +
      getCountryCallingCode('SG'),
    SX:
      intl.formatMessage({ id: 'country.SX1' }) +
      '  +' +
      getCountryCallingCode('SX'),
    SK:
      intl.formatMessage({ id: 'country.SK' }) +
      '  +' +
      getCountryCallingCode('SK'),
    SI:
      intl.formatMessage({ id: 'country.SI' }) +
      '  +' +
      getCountryCallingCode('SI'),
    SB:
      intl.formatMessage({ id: 'country.SB' }) +
      '  +' +
      getCountryCallingCode('SB'),
    SO:
      intl.formatMessage({ id: 'country.SO' }) +
      '  +' +
      getCountryCallingCode('SO'),
    ZA:
      intl.formatMessage({ id: 'country.ZA' }) +
      '  +' +
      getCountryCallingCode('ZA'),
    GS: intl.formatMessage({ id: 'country.GS' }) + '  +500',
    SS:
      intl.formatMessage({ id: 'country.SS1' }) +
      '  +' +
      getCountryCallingCode('SS'),
    ES:
      intl.formatMessage({ id: 'country.ES' }) +
      '  +' +
      getCountryCallingCode('ES'),
    LK:
      intl.formatMessage({ id: 'country.LK' }) +
      '  +' +
      getCountryCallingCode('LK'),
    SD:
      intl.formatMessage({ id: 'country.SD' }) +
      '  +' +
      getCountryCallingCode('SD'),
    SR:
      intl.formatMessage({ id: 'country.SR' }) +
      '  +' +
      getCountryCallingCode('SR'),
    SJ:
      intl.formatMessage({ id: 'country.SJ' }) +
      '  +' +
      getCountryCallingCode('SJ'),
    SZ:
      intl.formatMessage({ id: 'country.SZ' }) +
      '  +' +
      getCountryCallingCode('SZ'),
    TA:
      intl.formatMessage({ id: 'country.TA' }) +
      '  +' +
      getCountryCallingCode('TA'),
    SE:
      intl.formatMessage({ id: 'country.SE' }) +
      '  +' +
      getCountryCallingCode('SE'),
    CH:
      intl.formatMessage({ id: 'country.CH' }) +
      '  +' +
      getCountryCallingCode('CH'),
    SY:
      intl.formatMessage({ id: 'country.SY' }) +
      '  +' +
      getCountryCallingCode('SY'),
    TW:
      intl.formatMessage({ id: 'country.TW' }) +
      '  +' +
      getCountryCallingCode('TW'),
    TJ:
      intl.formatMessage({ id: 'country.TJ' }) +
      '  +' +
      getCountryCallingCode('TJ'),
    TZ:
      intl.formatMessage({ id: 'country.TZ' }) +
      '  +' +
      getCountryCallingCode('TZ'),
    TH:
      intl.formatMessage({ id: 'country.TH' }) +
      '  +' +
      getCountryCallingCode('TH'),
    TL:
      intl.formatMessage({ id: 'country.TL' }) +
      '  +' +
      getCountryCallingCode('TL'),
    TG:
      intl.formatMessage({ id: 'country.TG' }) +
      '  +' +
      getCountryCallingCode('TG'),
    TK:
      intl.formatMessage({ id: 'country.TK' }) +
      '  +' +
      getCountryCallingCode('TK'),
    TO:
      intl.formatMessage({ id: 'country.TO' }) +
      '  +' +
      getCountryCallingCode('TO'),
    TT:
      intl.formatMessage({ id: 'country.TT' }) +
      '  +' +
      getCountryCallingCode('TT'),
    TN:
      intl.formatMessage({ id: 'country.TN' }) +
      '  +' +
      getCountryCallingCode('TN'),
    TR:
      intl.formatMessage({ id: 'country.TR' }) +
      '  +' +
      getCountryCallingCode('TR'),
    TM:
      intl.formatMessage({ id: 'country.TM' }) +
      '  +' +
      getCountryCallingCode('TM'),
    TC:
      intl.formatMessage({ id: 'country.TC' }) +
      '  +' +
      getCountryCallingCode('TC'),
    TV:
      intl.formatMessage({ id: 'country.TV' }) +
      '  +' +
      getCountryCallingCode('TV'),
    UG:
      intl.formatMessage({ id: 'country.UG' }) +
      '  +' +
      getCountryCallingCode('UG'),
    UA:
      intl.formatMessage({ id: 'country.UA' }) +
      '  +' +
      getCountryCallingCode('UA'),
    AE:
      intl.formatMessage({ id: 'country.AE' }) +
      '  +' +
      getCountryCallingCode('AE'),
    GB:
      intl.formatMessage({ id: 'country.GB' }) +
      '  +' +
      getCountryCallingCode('GB'),
    US:
      intl.formatMessage({ id: 'country.US' }) +
      '  +' +
      getCountryCallingCode('US'),
    UM: intl.formatMessage({ id: 'country.UM' }) + '  +246',
    UY:
      intl.formatMessage({ id: 'country.UY' }) +
      '  +' +
      getCountryCallingCode('UY'),
    UZ:
      intl.formatMessage({ id: 'country.UZ' }) +
      '  +' +
      getCountryCallingCode('UZ'),
    VU:
      intl.formatMessage({ id: 'country.VU' }) +
      '  +' +
      getCountryCallingCode('VU'),
    VE:
      intl.formatMessage({ id: 'country.VE' }) +
      '  +' +
      getCountryCallingCode('VE'),
    VN:
      intl.formatMessage({ id: 'country.VN' }) +
      '  +' +
      getCountryCallingCode('VN'),
    VG:
      intl.formatMessage({ id: 'country.VG' }) +
      '  +' +
      getCountryCallingCode('VG'),
    VI:
      intl.formatMessage({ id: 'country.VI' }) +
      '  +' +
      getCountryCallingCode('VI'),
    WF:
      intl.formatMessage({ id: 'country.WF' }) +
      '  +' +
      getCountryCallingCode('WF'),
    EH:
      intl.formatMessage({ id: 'country.EH' }) +
      '  +' +
      getCountryCallingCode('EH'),
    YE:
      intl.formatMessage({ id: 'country.YE' }) +
      '  +' +
      getCountryCallingCode('YE'),
    ZM:
      intl.formatMessage({ id: 'country.ZM' }) +
      '  +' +
      getCountryCallingCode('ZM'),
    ZW:
      intl.formatMessage({ id: 'country.ZW' }) +
      '  +' +
      getCountryCallingCode('ZW'),
  };
};

export const DEFAULT_FORM_INPUT_CONFIG = {
  registration: {
    fields: [
      {
        name: 'firstName',
        type: 'text',
      },
      {
        name: 'lastName',
        type: 'text',
      },
      {
        name: 'email',
        isRequired: true,
      },
      {
        name: 'tel',
        isRequired: true,
        type: 'phone',
        defaultDistrict: 'HK',
      },
      {
        name: 'address1',
        isRequired: true,
        type: 'textarea',
      },
      {
        name: 'address2',
        type: 'textarea',
      },
      {
        name: 'zipCode',
      },
      {
        name: 'country',
        type: 'select',
        isRequired: true,
      },
      {
        name: 'arrivalHrs',
        type: 'select',
        isRequired: true,
      },
    ],
  },
};

export const DEFAULT_FORM_SELECT_OPTIONS = {
  registration: {
    country: countryCode.map(
      (x: { key: string; value: string; id: string }) => {
        return {
          key: x.key,
          value: x.value,
          id: x.id,
        };
      }
    ),
    arrivalHrs: [
      { value: '', id: 'reg.pleaseSelect', key: 'reg.arrival.hr' },
      { value: 12, key: '12:00' },
      { value: 13, key: '13:00' },
      { value: 14, key: '14:00' },
      { value: 15, key: '15:00' },
      { value: 16, key: '16:00' },
      { value: 17, key: '17:00' },
      { value: 18, key: '18:00' },
      { value: 19, key: '19:00' },
      { value: 20, key: '20:00' },
      { value: 21, key: '21:00' },
      { value: 22, key: '22:00' },
      { value: 23, key: '23:00' },
      { value: '00', key: '00:01' },
    ],
    evoucher: [
      { key: 'yes/no', value: undefined, id: 'reg.pleaseSelect' },
      { key: 'yes', value: 'true', id: 'reg.evoucher.yes' },
      { key: 'no', value: 'false', id: 'reg.evoucher.no' },
    ],
  },
};

export const DEFAULT_ROUTE_CONFIG = [
  {
    order: 1,
    route: 'booking-review',
  },
  {
    order: 2,
    route: 'registration',
  },
  {
    order: 3,
    route: 'checkinpayment',
  },
  {
    order: 4,
    route: 'confirm',
  },
];
