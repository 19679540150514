import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LinearProgressBar from 'components/linearProgress';
import { uploadPrearrivalDocument } from 'api';
import { useSelector, useDispatch } from 'react-redux';
import { selectBookingData, setBookingData } from 'store/slice/bookingSlice';
import { DocumentScanningStage } from 'types/DocumentScanning';
import Webcam from 'react-webcam';
import { Primary, Secondary, NoteColor } from '../../theme/colors';
import { useHistory, useLocation } from 'react-router-dom';
import ScanningIcon from '../../assets/images/scanIcon.png';
import IdCardIcon from '../../assets/images/idCard.png';
import IdCardIcon2 from '../../assets/images/idCard2.png';
import OkIcon from '../../assets/images/ok.png';
import useRouteTo from 'hooks/useRouteHandler';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '60px',
    fontSize: '24px',
    fontWeight: 400,
    color: Primary,
  },
  takeImageBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBlock: theme.spacing(3),
  },

  takeIdCardNote: {
    fontSize: '16px',
    fontWeight: 400,
    marginTop: theme.spacing(1),
    // width: '50%',
    textAlign: 'center',
    whiteSpace: 'normal',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  scanIconBox: {
    paddingBlock: theme.spacing(8),
  },
  imageFrameBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  uploadResultBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    marginBottom: theme.spacing(8),
  },
  uploadResultText: {
    fontSize: '20px',
    fontWeight: 600,
    marginBlock: theme.spacing(1),
    color: Primary,
  },
  scanIcon: {
    width: '200px',
    marginTop: theme.spacing(1),
  },
  idCardIcon: {
    width: '140px',
    marginTop: theme.spacing(1),
  },
  idCard2Icon: {
    width: '280px',
    marginBlock: theme.spacing(4),
  },
  okIcon: {
    width: '80px',
    marginTop: theme.spacing(1),
  },
  noteBox: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: NoteColor,
  },
  imagePreviewBox: {
    width: '320px',
    height: '240px',
    marginBlock: theme.spacing(8),
  },
  previewImage: {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  qrcode: {
    marginTop: theme.spacing(8),
  },
  buttonBox: {
    marginTop: theme.spacing(2),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      width: '80%',
      '& > *': {
        marginInline: theme.spacing(2),
      },
    },
  },
  button1: {
    background: Primary,
    color: Secondary,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
  button2: {
    background: Secondary,
    color: Primary,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
  button3: {
    background: Primary,
    color: Secondary,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: '0',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
  note: {
    margin: 0, // Remove default margin
    paddingBottom: theme.spacing(0.2), // Adjust spacing between notes
    textAlign: 'center',
  },
}));
interface LocationState {
  documentType: string;
}

export const TakeDocumentPhoto = () => {
  const bookingData = useSelector(selectBookingData);
  const dispatch = useDispatch();
  const classes = useStyles();
  const routeToNext = useRouteTo('next', {
    isBackFromAccompany: bookingData.currentAccompanyGuestId ? true : false,
  });

  const location = useLocation<LocationState>();
  const { documentType } = location.state || { documentType: '' };
  const [documentStage, setDocumentStage] = useState(
    DocumentScanningStage.Stage1
  );
  const [imageFile, setImageFile] = useState<string>('');
  const [uploadingResult, setUploadingResult] = useState<string>('');
  const [isUsingFrontCamera, setIsUsingFrontCamera] = useState<boolean>(false);
  const webcamRef = useRef<Webcam>(null);

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isCameraOn, setIsCameraOn] = useState<boolean>(false);

  const onCaptureButtonClick = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setImageFile(imageSrc);
    }

    setDocumentStage(DocumentScanningStage.Stage3);
  }, [webcamRef, setImageFile]);

  const onUploadImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      // Check file size (1MB = 1 * 1024 * 1024 bytes)
      const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
      if (file.size > maxSizeInBytes) {
        const img = new Image();
        const reader = new FileReader();

        // Define the onload event for the reader
        reader.onloadend = () => {
          // Set the src of the image to the file's data URL
          img.src = reader.result as string;
        };

        // Define the onload event for the image
        img.onload = () => {
          // Create a canvas element
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Set the desired width and height for the new image
          const maxWidth = 1800; // Desired width
          const maxHeight = 1800; // Desired height
          let width = img.width;
          let height = img.height;

          // Calculate the new dimensions while maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          // Set the canvas dimensions
          canvas.width = width;
          canvas.height = height;

          // Draw the image onto the canvas
          ctx?.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a Base64 string
          const base64String = canvas.toDataURL(file.type);
          setImageFile(base64String); // Set the Base64 string
          setDocumentStage(DocumentScanningStage.Stage3);
        };

        // Read the file as a Data URL (Base64)
        reader.readAsDataURL(file);
      } else {
        // If the file size is within limits, read it directly
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          setImageFile(base64String); // Set the Base64 string
          setDocumentStage(DocumentScanningStage.Stage3);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const onRetakeButtonClick = () => {
    setImageFile('');
    setDocumentStage(DocumentScanningStage.Stage1);
  };

  const onConfirmButtonClick = async () => {
    if (imageFile === '') {
      return;
    }

    const progressInterval = setInterval(() => {
      if (uploadProgress >= 100) {
        return;
      }
      setUploadProgress((prevProgress) => prevProgress + 2);
    }, 20);

    try {
      setDocumentStage(DocumentScanningStage.Stage4);
      const targetProfile = bookingData.currentAccompanyGuestId
        ? bookingData.accompanyGuests.find(
            (guest: any) =>
              guest.profileId === bookingData.currentAccompanyGuestId
          )
        : bookingData;
      const label =
        targetProfile.firstName +
        '_' +
        targetProfile.lastName +
        '_' +
        targetProfile.profileId;

      const uploadResult = await uploadPrearrivalDocument(
        bookingData.confirmationNumber,
        label,
        imageFile,
        bookingData.id,
        documentType,
        targetProfile.profileId
      );

      clearInterval(progressInterval);

      if (uploadResult.status === 204) {
        if (bookingData.currentAccompanyGuestId) {
          const newAccompanyGuests = bookingData.accompanyGuests.map(
            (guest: any) => {
              if (guest.profileId === bookingData.currentAccompanyGuestId) {
                return {
                  ...guest,
                  status: 'Completed',
                };
              }
              return guest;
            }
          );
          dispatch(
            setBookingData({
              ...bookingData,
              accompanyGuests: newAccompanyGuests,
              currentAccompanyGuestId: '',
            })
          );
        }
        setUploadingResult('success');
      } else {
        setUploadingResult('fail');
      }
    } catch (error) {
      console.log('error', error);
      clearInterval(progressInterval);
      setUploadingResult('fail');
    }
    setUploadProgress(0);
    setDocumentStage(DocumentScanningStage.Stage5);
  };

  useEffect(() => {
    if (uploadingResult !== '') {
      setDocumentStage(DocumentScanningStage.Stage5);
    }
  }, [uploadingResult]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileClick = () => {
    console.log('handleFileClick');
    fileInputRef.current = null;
  };

  useEffect(() => {
    // Check the webcam status
    const checkCamAvailability = async () => {
      try {
        // This will throw an error if webcam is not available
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setIsCameraOn(true);
        stream.getTracks().forEach((track) => track.stop()); // Stop the stream
      } catch (error) {
        setIsCameraOn(false);
      }
    };

    checkCamAvailability();
  }, []);

  const SelectImageTakingMethod: FC = () => {
    return (
      <>
        <Box className={classes.noteBox}>
          <Typography className={classes.note}>
            <FormattedMessage id="takeDocumentPhoto.noteTitle" />
          </Typography>
          {[1, 2, 3].map((note) => (
            <Typography key={note} className={classes.note}>
              <FormattedMessage id={`takeDocumentPhoto.note${note}`} />
            </Typography>
          ))}
        </Box>

        <Box className={classes.scanIconBox}>
          <img
            className={classes.scanIcon}
            src={ScanningIcon}
            alt="ScanningIcon"
          />
        </Box>

        <Box className={classes.buttonBox}>
          <Button
            className={classes.button1}
            variant="contained"
            type="button"
            onClick={() => {
              setDocumentStage(DocumentScanningStage.Stage2);
            }}
          >
            <Typography>
              <FormattedMessage id="btn.take" />
            </Typography>
          </Button>
          <Button
            className={classes.button2}
            variant="contained"
            component="label"
            onClick={handleFileClick}
          >
            <input
              type="file"
              accept="image/jpeg, image/png, image/jpg"
              onChange={onUploadImageChange}
              ref={fileInputRef}
              hidden
            />
            <Typography>
              <FormattedMessage id="btn.upload" />
            </Typography>
          </Button>
        </Box>
      </>
    );
  };

  const DocumentTakeAndUpload: FC = () => {
    return (
      <>
        {uploadingResult !== 'success' && (
          <Box className={classes.noteBox}>
            <Typography className={classes.note}>
              <FormattedMessage id="takeDocumentPhoto.takePhotoInstruction1" />
            </Typography>
          </Box>
        )}

        {documentStage === DocumentScanningStage.Stage2 ? (
          <>
            <Grid container className={classes.takeImageBox} spacing={2}>
              <Grid item className={classes.scanIconBox} xs={12} md={4}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: { xs: 'center', md: 'end' },
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        xs: '100%',
                        md: '140px',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      className={classes.idCardIcon}
                      src={IdCardIcon}
                      alt="IdCardIcon"
                    />
                    <Typography className={classes.takeIdCardNote}>
                      <FormattedMessage id="takeDocumentPhoto.takePhotoInstruction2" />
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item className={classes.imageFrameBox} xs={12} md={8}>
                <Box
                  sx={{
                    width: {
                      xs: '320px',
                      md: '400px',
                    },
                    // height: '300px',
                    height: {
                      xs: '240px',
                      md: '300px',
                    },
                    borderRadius: '10px',
                    overflow: 'hidden',
                  }}
                  onClick={() => {
                    setIsUsingFrontCamera((prev) => !prev);
                  }}
                >
                  <Webcam
                    videoConstraints={{
                      facingMode: isUsingFrontCamera ? 'user' : 'environment',
                    }}
                    audio={false}
                    ref={webcamRef}
                    height={'100%'}
                    width={'100%'}
                    screenshotFormat="image/jpeg"
                  />
                </Box>
              </Grid>
            </Grid>
            <Box className={classes.buttonBox}>
              <Button
                className={classes.button3}
                variant="contained"
                type="button"
                onClick={() => {
                  onCaptureButtonClick();
                }}
                disabled={!isCameraOn}
              >
                <FormattedMessage id="btn.capture" />
              </Button>
            </Box>
          </>
        ) : documentStage === DocumentScanningStage.Stage3 ? (
          <>
            <Box className={classes.imagePreviewBox}>
              <img
                className={classes.previewImage}
                src={imageFile as string}
                alt="ScanningIcon"
              />
            </Box>
            <Box className={classes.buttonBox}>
              <Button
                className={classes.button2}
                variant="contained"
                type="button"
                onClick={() => {
                  onRetakeButtonClick();
                }}
              >
                <FormattedMessage id="btn.retake" />
              </Button>
              <Button
                className={classes.button1}
                variant="contained"
                type="button"
                onClick={() => {
                  onConfirmButtonClick();
                }}
              >
                <FormattedMessage id="btn.confirm" />
              </Button>
            </Box>
          </>
        ) : documentStage === DocumentScanningStage.Stage4 ? (
          <>
            <Box className={classes.imagePreviewBox}>
              <img
                className={classes.previewImage}
                src={imageFile as string}
                alt="ScanningIcon"
              />
            </Box>
            <Typography className={classes.note}>
              <FormattedMessage id="takeDocumentPhoto.uploading" />
            </Typography>
            <LinearProgressBar progress={uploadProgress} />
          </>
        ) : (
          //DocumentScanningStage.Stage5
          <>
            <Box className={classes.uploadResultBox}>
              {uploadingResult === 'success' ? (
                <>
                  <Typography className={classes.uploadResultText}>
                    <FormattedMessage id="takeDocumentPhoto.uploadSuccess" />
                  </Typography>

                  <img
                    src={IdCardIcon2}
                    alt="IdCardIcon2"
                    className={classes.idCard2Icon}
                  />

                  <img src={OkIcon} alt="OkIcon" className={classes.okIcon} />
                </>
              ) : (
                <Typography className={classes.uploadResultText}>
                  <FormattedMessage id="takeDocumentPhoto.uploadFail" />
                </Typography>
              )}
            </Box>
            {uploadingResult === 'success' ? (
              <Box className={classes.buttonBox}>
                <Button
                  className={classes.button3}
                  variant="contained"
                  type="button"
                  onClick={() => {
                    routeToNext();
                  }}
                >
                  <FormattedMessage id="btn.confirm" />
                </Button>
              </Box>
            ) : (
              <Box className={classes.buttonBox}>
                <Button
                  className={classes.button1}
                  variant="contained"
                  type="button"
                  onClick={() => {
                    onRetakeButtonClick();
                  }}
                >
                  <FormattedMessage id="btn.retake" />
                </Button>
                <Button
                  className={classes.button2}
                  variant="contained"
                  type="button"
                  onClick={() => {
                    onConfirmButtonClick();
                  }}
                >
                  <FormattedMessage id="btn.retry" />
                </Button>
              </Box>
            )}
          </>
        )}
      </>
    );
  };

  const DocumentScanningContent: FC = () => {
    switch (documentStage) {
      case DocumentScanningStage.Stage1:
        return <SelectImageTakingMethod />;
      default:
        return <DocumentTakeAndUpload />;
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box className={classes.paper}>
        {uploadingResult !== 'success' && (
          <Typography className={classes.title}>
            <FormattedMessage id="takeDocumentPhoto.title" />
          </Typography>
        )}
        <DocumentScanningContent />
      </Box>
    </Container>
  );
};
