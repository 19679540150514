import { useLocation, useHistory } from 'react-router-dom'; // Make sure to install react-router-dom if you haven't
import { selectHotelConfig } from '../store/slice/hotelConfigSlice';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { DEFAULT_ROUTE_CONFIG } from '../constants';

const useRouteTo = (type: 'previous' | 'next', dataToNextRoute: any = {}) => {
  try {
    const history = useHistory();
    const location = useLocation(); // Get the current location from React Router
    const routeConfig =
      useSelector(selectHotelConfig)?.routeOrder || DEFAULT_ROUTE_CONFIG; // Get the route config from the Redux store

    const routeHandler = useCallback(() => {
      const pathName = location.pathname
        ?.replace('/en/', '')
        .replace('/hk/', '')
        .replace('/cn/', '');

      const currentRoute = routeConfig?.filter(
        (route: any) => route.route === pathName
      )?.[0];

      const indexOfCurrentRoute = currentRoute.order;

      //special case start
      if (
        dataToNextRoute?.isBackFromAccompany &&
        currentRoute.route === 'take-document-photo'
      ) {
        return history.push('add-guest', { isRegisteringAccompany: true });
      }

      //special case end
      const routeTo =
        type === 'previous'
          ? routeConfig?.filter(
              (route: any) => route.order === indexOfCurrentRoute - 1
            )?.[0]?.route
          : routeConfig?.filter(
              (route: any) => route.order === indexOfCurrentRoute + 1
            )?.[0]?.route;

      return history.push(routeTo, dataToNextRoute);
    }, [useHistory, useLocation, useSelector, selectHotelConfig, routeConfig]);

    return routeHandler;
  } catch (e: any) {
    console.error(e);
    throw new Error(e.message);
  }
};

export default useRouteTo;
