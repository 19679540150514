import React from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Redirect } from 'react-router-dom';
import { AppLanguage } from 'const';
import { LanguageStrings } from '../localizations';



interface Props {
  RouterComponent: React.ComponentClass<any>;
  languages: { [k: number]: string };
  appStrings: { [prop: string]: LanguageStrings };
  defaultLanguage?: AppLanguage;
}


export const LocalizedRouter: React.FC<Props> = ({
  children,
  RouterComponent,
  appStrings,
  defaultLanguage
}) => (
 
  <RouterComponent>
    <Route path="*/:lang([a-z]{2})">
      {({ match, location }) => {
        /**
         * Get current language
         * Set default locale to en if base path is used without a language
         */
        const params = match ? match.params : {};
        
        
        let { lang = defaultLanguage || AppLanguage.English } = params;
        let parmLang = localStorage.getItem("lang");
        if(parmLang!==null && parmLang!=='undefined' && parmLang!==''){
          lang = parmLang? parmLang:AppLanguage.English;
        }
        if(lang==='undefined' || lang==='' || lang === null){
          lang = AppLanguage.English;
        }

        
        //console.log("lang: "+lang);
        /**
         * If language is not in route path, redirect to language root
         */
 
        let { pathname } = location;
        /*if(!params.lang){
          var splitted = pathname.split("/", );
          var idx = splitted.length>0?(splitted.length - 1):splitted.length;
          console.log("Splitted Name: " + splitted[idx]);
          localStorage.setItem("id",splitted[idx]);
        }*/

        /*let parmFn = localStorage.getItem("fn");
        if(parmFn!==null && parmFn!=='undefined' && parmFn!==''){
          window.location.href = `/${CONTEXT_ROOT}/${lang}/${parmFn}`;
        }*/
        
        //console.log("pathname: " + pathname);
        if (!pathname.includes(`/${lang}/`)) {
          return <Redirect to={`/${lang}/`} />;
        }

        /**
         * Return Intl provider with default language set
         */
        return (
          <IntlProvider locale={lang} messages={appStrings[lang]}>
            {children}
          </IntlProvider>
        );
      }}
    </Route>
  </RouterComponent>
);
