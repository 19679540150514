import { selectHotelConfig } from '../store/slice/hotelConfigSlice';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

const useGetFormInputConfig = (formName: string) => {
  const hotelConig = useSelector(selectHotelConfig);

  const getFormInputConfig = useCallback(() => {
    const inputConfig = hotelConig?.input?.[formName]['fields'];
    return inputConfig;
  }, [useSelector, selectHotelConfig, hotelConig]);

  return getFormInputConfig();
};

export default useGetFormInputConfig;
