import React from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null; // The error can either be an Error object or null
}

class FatalErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false, error: null }; // Initialize error state
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error }; // Store the error
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // Log the error to an error reporting service (optional)
    console.error('Error caught in FatalErrorBoundary:', error, info);
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI with error message
      return (
        <div>
          <h1>Something went wrong.</h1>
          <p>{this.state.error ? this.state.error.message : 'Unknown error'}</p>
          <p>Please try refreshing the page or come back later.</p>
        </div>
      );
    }

    return this.props.children; // Render children if no error
  }
}

export default FatalErrorBoundary;
